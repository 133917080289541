import React, { useEffect, useState } from 'react';
import { Box, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel } from '@mui/material';
import { useTranslation } from '@/hooks/translations';
import { useUserContext } from '@/contexts/user';
import TimeRangeForm, { formatISOTimezone, ITimeRangeForm } from '@/components/TimeRangeForm';
import { saveAs } from 'file-saver';
import { cloneDeep } from 'lodash';
import TransactionService from '@/services/transaction';
import OrdersService from '@/services/order';
import { SelectMultipleRestaurants } from '@/components/SelectMultipleRestaurants';
import { useRestaurantContext } from '@/contexts/restaurant';
import CustomModal from '@/components/CustomModal';
import { restrictRoles } from '@/constants/roleRestrictions';
import { onPushEvent } from '@/services/lib/gtm';
import { endOfDay, formatISO, startOfDay } from 'date-fns';

type Props = {
    open: boolean;
    onClose: () => void;
    PickersDay?: React.ComponentType<any>;
};

export default function ExportModal(props: Props) {
    const transactionService = TransactionService.getInstance();
    const orderService = OrdersService.getInstance();
    const { t } = useTranslation('common');
    const [exportType, setExportType] = useState<string>('order');
    const { user } = useUserContext();
    const [loading, setLoading] = useState(false);
    const date = new Date();

    const [timeRange, setTimeRange] = useState<ITimeRangeForm>({
        type: 'today',
        from: formatISO(startOfDay(date)),
        to: formatISO(endOfDay(date)),
    });
    const [reportFileFormat, setReportFileFormat] = useState<string>('csv');
    const [restaurantListToExport, setRestaurantListToExport] = useState<string[]>([]);
    const { restaurant } = useRestaurantContext();

    const isBrand = restrictRoles.EXPORT_BRAND_REPORTS.includes(user?.userData?.role);

    const exportTypeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setExportType((e.target as HTMLInputElement).value);
    };

    const fileTypeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setReportFileFormat((e.target as HTMLInputElement).value);
    };

    const saveFile = (data: Blob, name: string) => {
        const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, name);
    };

    const getDateQuery = () => {
        if (timeRange.type === 'all' || !timeRange.from || !timeRange.to) {
            return {
                startDate: undefined,
                endDate: undefined,
                type: undefined,
            };
        }

        return {
            startDate: formatISOTimezone(timeRange.from),
            endDate: formatISOTimezone(timeRange.to),
            type: timeRange.type,
        };
    };

    const closeHandler = () => {
        setExportType('order');
        setTimeRange({
            type: 'today',
            from: formatISO(startOfDay(date)),
            to: formatISO(endOfDay(date)),
        });
        setReportFileFormat('csv');
        setRestaurantListToExport([]);
        setLoading(false);
        props.onClose();
    };

    const exportTransaction = () => {
        setLoading(true);
        const { startDate, endDate, type } = getDateQuery();
        (isBrand
            ? transactionService.downloadBrandTransaction(restaurant?.brand_id || '', {
                  fileFormat: reportFileFormat,
                  restaurantIds: restaurantListToExport.join(','),
                  startDate,
                  endDate,
                  type,
              })
            : transactionService.downloadRestaurantTransaction(restaurant?.id || '', {
                  fileFormat: reportFileFormat,
                  startDate,
                  endDate,
                  type,
              })
        )
            .then((response) => {
                saveFile(response.data, `transactions.${reportFileFormat}`);
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
                closeHandler();
            });
    };

    const exportOrder = () => {
        setLoading(true);
        const { startDate, endDate, type } = getDateQuery();
        (isBrand
            ? orderService.downloadBrandOrder(restaurant?.brand_id || '', {
                  fileFormat: reportFileFormat,
                  restaurantIds: restaurantListToExport.join(','),
                  startDate,
                  endDate,
                  type,
              })
            : orderService.downloadRestaurantOrder(restaurant?.id || '', {
                  fileFormat: reportFileFormat,
                  startDate,
                  endDate,
                  type,
              })
        )
            .then((response) => {
                saveFile(response.data, `orders.${reportFileFormat}`);
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
                closeHandler();
            });
    };

    const getActionMethod = () => {
        onPushEvent('user_use_export_function');
        switch (exportType) {
            case 'transaction':
                exportTransaction();
                break;
            case 'order':
                exportOrder();
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        onPushEvent(`user_choose_${reportFileFormat}_view_${timeRange}_${exportType}`);
    }, [exportType, timeRange, reportFileFormat]);

    return (
        <CustomModal
            {...props}
            title={t('Export Payments')}
            actionText={t('Export')}
            actionMethod={getActionMethod}
            maxWidth="sm"
            disableButton={loading}
            onClose={closeHandler}
        >
            <Box marginBottom={2}>
                <FormControl>
                    <FormLabel>{t('Report Type')}</FormLabel>

                    <RadioGroup row value={exportType} onChange={exportTypeChangeHandler} sx={{ display: 'flex' }}>
                        <FormControlLabel value="order" control={<Radio />} label={t('Order View')} />
                        <FormControlLabel value="transaction" control={<Radio />} label={t('Transaction View')} />
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box marginBottom={2}>
                {/* <TimeRangeForm
                    value={timeRange}
                    onChange={(value) => {
                        setTimeRange(cloneDeep(value));
                    }}
                    timeSelectEnabled
                /> */}
                {TimeRangeForm({
                    value: timeRange,
                    onChange: (value) => {
                        setTimeRange(cloneDeep(value));
                    },
                    timeSelectEnabled: true,
                })}
            </Box>
            {isBrand && (
                <Box marginBottom={2}>
                    <FormControl fullWidth>
                        <FormLabel>{t('Restaurants')}</FormLabel>
                        <SelectMultipleRestaurants
                            onChange={(value) => {
                                setRestaurantListToExport(value);
                            }}
                            restaurantListToExport={restaurantListToExport}
                        />
                    </FormControl>
                </Box>
            )}
            <Box marginBottom={2}>
                <FormControl>
                    <FormLabel>{t('File Type')}</FormLabel>
                    <RadioGroup row value={reportFileFormat} onChange={fileTypeChangeHandler} sx={{ display: 'flex' }}>
                        <FormControlLabel value="csv" control={<Radio />} label={t('.csv')} />
                        <FormControlLabel value="xlsx" control={<Radio />} label={t('.xlsx')} />
                    </RadioGroup>
                </FormControl>
            </Box>
        </CustomModal>
    );
}
