import { IQsrOrder } from '@/views/QsrOrders/types';
import { useTranslation } from '@/hooks/translations';
import classNames from 'classnames';

import styles from './index.module.scss';

interface IProps {
    order: IQsrOrder;
}

export default function QsrOrderInfo({ order }: IProps) {
    const { t } = useTranslation('common');

    return (
        <div className={styles.main}>
            {order.subTotalStr && (
                <div className={styles.row}>
                    <div className={styles.label}>{t('Subtotal')}</div>
                    <div className={styles.value}>{order.subTotalStr}</div>
                </div>
            )}
            {order.totalStr && (
                <div className={styles.row}>
                    <div className={styles.label}>{t('Total')}</div>
                    <div className={styles.value}>{order.totalStr}</div>
                </div>
            )}
            {order.customerComment && (
                <div className={classNames(styles.row, styles.comment)}>
                    <div className={classNames(styles.label, styles.fullWidth)}>{t('Customer Comment')}</div>
                    <div className={styles.value}>{order.customerComment}</div>
                </div>
            )}
        </div>
    );
}
