import {
    Box,
    Grid,
    Typography,
    Button,
    Switch,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    IconButton,
    TextField,
    TableCell,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties, ReactNode } from 'react';
import { grays } from '@/theme';
import LoadingContent from './LoadingContent';

export const Input = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderWidth: '2px',
            borderColor: grays.dark,
        },
        '&:hover fieldset': {
            borderColor: grays.dark,
        },
        '&.Mui-focused fieldset': {
            borderWidth: '2px',
            borderColor: theme.palette.primary.main,
        },
    },
}));

export const Section = styled(Grid)(({ theme }) => ({
    borderRadius: '1rem',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    padding: '1rem',
}));

type PageTitleProps = {
    text?: ReactNode;
    button?: ReactNode;
    pageTitle?: string;
    sx?: CSSProperties;
};

export const HeaderPageTitle = ({ text, pageTitle }: PageTitleProps) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            paddingBottom: '0rem',
        }}
    >
        <Typography
            sx={{
                fontSize: '20px',
                fontWeight: 500,
                fontStyle: 'normal',
                lineHeight: '38,4px',
                paddingTop: '0.5rem',
            }}
            gutterBottom
        >
            {text}
            {pageTitle}
        </Typography>
    </Box>
);

export const PageTitle = ({ text, button, sx }: PageTitleProps) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: {
                sm: 'row',
                xs: 'column',
            },
            justifyContent: 'space-between',
            alignItems: {
                sm: 'center',
                xs: 'start',
            },
            paddingBottom: {
                sm: '0rem',
                xs: '1rem',
            },
            ...sx,
        }}
    >
        {text && (
            <Typography
                sx={{
                    fontSize: {
                        sm: 40,
                        xs: 30,
                    },
                }}
                gutterBottom
            >
                {text}
            </Typography>
        )}
        {button && <Box sx={{ flex: '1 1', mb: '12px', display: 'flex', justifyContent: 'end' }}>{button}</Box>}
    </Box>
);

type PrimaryButtonProps = {
    text: any;
    styles?: any;
    [x: string]: any;
};

export const PrimaryButton = ({ text, styles, ...rest }: PrimaryButtonProps) => (
    <Button variant="contained" sx={{ textTransform: 'none', ...styles }} size="large" disableElevation {...rest}>
        {text}
    </Button>
);

export const CustomButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.primary,
    textTransform: 'none',
    padding: '0.5rem 1.5rem',
    '&:hover': {
        backgroundColor: theme.palette.grey[200],
    },
}));

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.primary.main,
    borderRadius: '0.4rem',
    paddingRight: '0.7rem',
    paddingLeft: '0.7rem',
    '&:hover': {
        backgroundColor: theme.palette.grey[200],
    },
}));

export const CustomSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.main : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

type TableContentProps = {
    heading?: string;
    options?: ReactNode;
    headCells: ReactNode;
    large?: boolean;
    loading?: boolean;
    error?: string;
    children: ReactNode;
};

export const TableContent = ({ heading, options, headCells, large, loading, error, children }: TableContentProps) => {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: {
                        sm: 'row',
                        xs: 'column',
                    },
                    justifyContent: 'space-between',
                    alignItems: {
                        sm: 'center',
                        xs: 'start',
                    },
                }}
            >
                {heading && (
                    <Typography variant="h5" sx={{ marginLeft: '1rem' }} gutterBottom>
                        {heading}
                    </Typography>
                )}
                {options && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: {
                                sm: 'row',
                                xs: 'column',
                            },
                        }}
                    >
                        {options}
                    </Box>
                )}
            </Box>
            <LoadingContent loading={loading || false} error={error || ''}>
                <TableContainer>
                    <Table size={large ? 'medium' : 'small'}>
                        <TableHead>
                            <TableRow>{headCells}</TableRow>
                        </TableHead>
                        <TableBody>{children}</TableBody>
                    </Table>
                </TableContainer>
            </LoadingContent>
        </>
    );
};

export const Cell = styled(TableCell)({
    borderBottom: 'none',
});

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
}));
