import { IQsrOrder } from '@/views/QsrOrders/types';
import { useMemo } from 'react';
import { isEqual, pick } from 'lodash';
import { useTranslation } from '@/hooks/translations';
import CopyToClipboard from '@/common/CopyToClipboard';
import classNames from 'classnames';

import styles from './index.module.scss';

interface IProps {
    order: IQsrOrder;
    fields: string[];
    normalMargin?: boolean;
}

export default function QsrUserInfo({ order, fields, normalMargin }: IProps) {
    if (fields.length === 0) {
        return null;
    }

    const { t } = useTranslation('common');
    const info = useMemo<{ [key: string]: string }>(() => {
        const { meta, ...rest } = order.userInfo || {};
        return pick({ ...rest, ...meta }, fields);
    }, [order]);

    if (isEqual(info, {})) {
        return null;
    }

    const getLabel = (fieldName: string) => {
        switch (fieldName) {
            case 'name':
                return t('Name');
            case 'email':
                return t('Email');
            case 'phone':
                return t('Phone');
            case 'address':
                return t('Shipping Address');
            case 'carPlate':
                return t('Car Plate');
            case 'cpf':
                return t('CPF');
            default:
                return '';
        }
    };

    const getFieldContent = (fieldName: string) => {
        const field = info[fieldName];
        if (!field) {
            return null;
        }
        return (
            <div className={styles.field}>
                <CopyToClipboard value={field} />
                <div className={styles.label}>{getLabel(fieldName)}</div>
                <div className={styles.value}>{field}</div>
            </div>
        );
    };

    return (
        <div className={classNames(styles.main, { [styles.fit]: !normalMargin })}>
            {fields.map((field) => {
                return getFieldContent(field);
            })}
        </div>
    );
}
