import { createContext, ReactNode, useState, useEffect, useContext } from 'react';
import { IRestaurant } from '@/views/OrdersTableView/types';
import { accessTokenKey, chosenRestaurantIdKey, restaurantDataKey } from '@/config/axios';
import RestaurantService from '@/services/restaurant';

interface IRestaurantContext {
    restaurant: IRestaurant | null;
    setRestaurant: ((val: IRestaurant | null) => void) | any;
    selectedQrGroup: string | null;
    setSelectedQrGroup: ((val: string | null) => void) | any;
}

const getLSRestaurant = (): IRestaurant | null => {
    try {
        const item = localStorage.getItem(restaurantDataKey);
        if (item) {
            return JSON.parse(item);
        }
    } catch {
        return null;
    }
    return null;
};

const setLSRestaurant = (item: IRestaurant | null) => {
    localStorage.setItem(restaurantDataKey, JSON.stringify(item));
};

const RestaurantContext = createContext<IRestaurantContext>({
    restaurant: null,
    setRestaurant: null,
    selectedQrGroup: null,
    setSelectedQrGroup: null,
});

type Props = {
    children?: ReactNode;
};

export function RestaurantContextProvider({ children }: Props) {
    const restaurantService = RestaurantService.getInstance();
    const [restaurant, setRestaurant] = useState<IRestaurant | null>(getLSRestaurant());
    const [selectedQrGroup, setSelectedQrGroup] = useState<string | null>(null);

    useEffect(() => {
        const token = localStorage.getItem(accessTokenKey);
        const restaurantId = localStorage.getItem(chosenRestaurantIdKey);
        if (token && restaurantId) {
            restaurantService
                .getRestaurant(restaurantId)
                .then((data) => {
                    setRestaurant(data);
                    setLSRestaurant(data);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <RestaurantContext.Provider value={{ restaurant, setRestaurant, selectedQrGroup, setSelectedQrGroup }}>
            {children}
        </RestaurantContext.Provider>
    );
}

export const useRestaurantContext = () => useContext(RestaurantContext);
