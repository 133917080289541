import React, { CSSProperties, ReactElement, useMemo, useState } from 'react';
import { useTranslation } from '@/hooks/translations';
import { Chip, IconButton, Typography, SwipeableDrawer, Box, Drawer } from '@mui/material';
import {
    ViewStreamRounded,
    ViewComfyRounded,
    EditRounded,
    CloseRounded,
    TableBarRounded,
    CreditCardRounded,
} from '@mui/icons-material';
import { useNavbarContext } from '@/contexts/navbar';
import { PaymentStatus } from '@/services/utils/order';
import { ITableParams } from '@/views/OrdersTableView/types';
import { clone, cloneDeep } from 'lodash';
import SelectTables from '@/components/SelectTables';
import CommonModal from '@/components/CommonModal';
import TimeRangeForm, { ITimeRangeForm } from '@/components/TimeRangeForm';
import { onPushEvent } from '@/services/lib/gtm';

type TableType = 'assigned_to_me' | 'selected_tables';
type FilterType = PaymentStatus | TableType | 'date_range';

export interface ITableFilters {
    view: 'list' | 'grid';
    pagination: -1 | 50 | 100 | 150 | 200;
    sort: 'order' | 'table';
    filter: FilterType[];
    numberOfTable: number;
    selectedTables: ITableParams[];
    dateRange: ITimeRangeForm;
}

export const drawerStyles: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '30px 16px',
    background: '#FFFFF',
    borderRadius: '32px 32px 0px 0px',
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '146.02%',
    textAlign: 'center',
    letterSpacing: '0.0025em',
};

export const rightDrawerStyles: CSSProperties = {
    ...drawerStyles,
    borderRadius: 0,
    width: '500px',
};

// const iconStyle: CSSProperties = {
//     fontSize: '18px',
//     background: '#7D00D4',
//     borderRadius: '50%',
//     color: '#FFFFFF !important',
//     padding: '2px',
// };

interface IViewItem {
    label: string;
    value: 'list' | 'grid';
    iconSelected: ReactElement;
    iconUnselected: ReactElement;
}

interface IPaginationItem {
    label: string;
    value: -1 | 50 | 100 | 150 | 200;
}

interface ISortItem {
    label: string;
    value: 'order' | 'table';
    iconSelected: ReactElement;
    iconUnselected: ReactElement;
}

interface IFilterItem {
    label: string;
    value: FilterType;
    icon?: ReactElement;
}

interface ITableViewFilterProps {
    vpos?: boolean;
    tables: ITableParams[];
    value: ITableFilters;
    onChange: (value: ITableFilters) => void;
    disableDateFilter?: boolean;
}

const TableViewFilter = ({ tables, value, onChange, disableDateFilter }: ITableViewFilterProps) => {
    const { t } = useTranslation('common');
    const { isSettingsOpen, setIsSettingsOpen } = useNavbarContext();
    const [selectTableModalOpen, setSelectTableModalOpen] = useState(false);
    const [timeRangeModalOpen, setTimeRangeModalOpen] = useState(false);
    const [timeRange, setTimeRange] = useState<ITimeRangeForm>({
        type: 'today',
        from: null,
        to: null,
    });

    useMemo(() => {
        setTimeRange(value.dateRange);
    }, [value.dateRange]);

    const viewOptions = useMemo<IViewItem[]>(
        () => [
            {
                label: t('Tile'),
                value: 'grid',
                iconSelected: <ViewComfyRounded sx={{ color: '#7D00D4 !important' }} />,
                iconUnselected: <ViewComfyRounded sx={{ color: '#616475 !important' }} />,
            },
            {
                label: t('List'),
                value: 'list',
                iconSelected: <ViewStreamRounded sx={{ color: '#7D00D4 !important' }} />,
                iconUnselected: <ViewStreamRounded sx={{ color: '#616475 !important' }} />,
            },
        ],
        [t],
    );

    const paginationOptions = useMemo<IPaginationItem[]>(
        () => [
            {
                label: t('Disable'),
                value: -1,
            },
            {
                label: t('50'),
                value: 50,
            },
            {
                label: t('100'),
                value: 100,
            },
            {
                label: t('150'),
                value: 150,
            },
            {
                label: t('200'),
                value: 200,
            },
        ],
        [t],
    );

    const sortOptions = useMemo<ISortItem[]>(
        () => [
            {
                label: t('Order'),
                value: 'order',
                iconSelected: <CreditCardRounded sx={{ color: '#7D00D4 !important' }} />,
                iconUnselected: <CreditCardRounded sx={{ color: '#616475 !important' }} />,
            },
            {
                label: t('Table'),
                value: 'table',
                iconSelected: <TableBarRounded sx={{ color: '#7D00D4 !important' }} />,
                iconUnselected: <TableBarRounded sx={{ color: '#616475 !important' }} />,
            },
        ],
        [t],
    );

    const filterOptions = useMemo<IFilterItem[]>(
        () => [
            {
                label: t('Paid'),
                value: 'paid',
            },
            {
                label: t('Partially Paid'),
                value: 'paid_partially',
            },
            // {
            //     label: t('Assigned to me'),
            //     value: 'assigned_to_me',
            // },
            {
                label: t('Select my tables'),
                value: 'selected_tables',
                icon: <EditRounded sx={{ color: '#1A1C23 !important' }} />,
            },
            {
                label: t('Date Range'),
                value: 'date_range',
                icon: <EditRounded sx={{ color: '#1A1C23 !important' }} />,
            },
        ],
        [t],
    );

    return (
        <>
            <SwipeableDrawer
                PaperProps={{ sx: drawerStyles }}
                anchor="bottom"
                onOpen={() => setIsSettingsOpen(true)}
                open={isSettingsOpen}
                onClose={() => setIsSettingsOpen(false)}
            >
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '25px',
                        right: '25px',
                        color: '#9B9EAB',
                    }}
                    onClick={() => setIsSettingsOpen(false)}
                >
                    <CloseRounded />
                </IconButton>

                <Typography sx={{ marginBottom: '25px', fontWeight: 500, fontSize: '16px' }}>{t('Filters')}</Typography>
                <Typography sx={{ marginBottom: '10px', fontWeight: 500 }}>{t('View as')}</Typography>
                <Box>
                    {viewOptions.map((option) => {
                        const selected = value.view === option.value;
                        return (
                            <Chip
                                clickable
                                onClick={() => {
                                    const val = cloneDeep(value);
                                    val.view = option.value;
                                    onChange(val);
                                    onPushEvent(`choose_view_as_${option.value}`);
                                }}
                                variant="outlined"
                                icon={selected ? option.iconSelected : option.iconUnselected}
                                label={option.label}
                                style={{
                                    borderRadius: '8px',
                                    margin: '5px',
                                    padding: '10px',
                                    background: selected ? '#F6EBFC' : '#FFFFFF',
                                    borderColor: '#EBECF2',
                                }}
                            />
                        );
                    })}
                </Box>
                <Typography sx={{ marginBottom: '10px', fontWeight: 500 }}>{t('Pagination')}</Typography>
                <Box>
                    {paginationOptions.map((option) => {
                        const selected = value.pagination === option.value;

                        return (
                            <Chip
                                clickable
                                onClick={() => {
                                    const val = cloneDeep(value);
                                    val.pagination = option.value;
                                    onChange(val);
                                    onPushEvent(`choose_pagination_as_${option.value}`);
                                }}
                                variant="outlined"
                                label={option.label}
                                style={{
                                    borderRadius: '8px',
                                    margin: '5px',
                                    padding: '10px',
                                    background: selected ? '#F6EBFC' : '#FFFFFF',
                                    borderColor: '#EBECF2',
                                }}
                            />
                        );
                    })}
                </Box>
                <Typography sx={{ marginBottom: '10px', fontWeight: 500 }}>{t('Sort by')}</Typography>
                <Box>
                    {sortOptions.map((option) => {
                        const selected = value.sort === option.value;
                        return (
                            <Chip
                                clickable
                                onClick={() => {
                                    const val = cloneDeep(value);
                                    val.sort = option.value;
                                    onChange(val);
                                    onPushEvent(`choose_order_by_${option.value}`);
                                }}
                                variant="outlined"
                                icon={selected ? option.iconSelected : option.iconUnselected}
                                label={option.label}
                                style={{
                                    borderRadius: '8px',
                                    margin: '5px',
                                    padding: '10px',
                                    background: selected ? '#F6EBFC' : '#FFFFFF',
                                    borderColor: '#EBECF2',
                                }}
                            />
                        );
                    })}
                </Box>
                <Typography sx={{ marginBottom: '10px', fontWeight: 500 }}>{t('Filter by')}</Typography>
                <Box>
                    {filterOptions.map((option) => {
                        if (disableDateFilter && option.value === 'date_range') {
                            return null;
                        }
                        const index = value.filter.indexOf(option.value);
                        return (
                            <Chip
                                variant="outlined"
                                icon={option?.icon}
                                label={option.label}
                                style={{
                                    borderRadius: '8px',
                                    margin: '5px',
                                    padding: '5px',
                                    background: index > -1 ? '#F6EBFC' : '#FFFFFF',
                                    borderColor: '#EBECF2',
                                }}
                                clickable
                                onClick={() => {
                                    const val = cloneDeep(value);
                                    if (option.value === 'selected_tables') {
                                        onPushEvent('choose_select_my_tables');
                                        setSelectTableModalOpen(true);
                                        if (val.selectedTables.length > 0) {
                                            if (!val.filter.includes(option.value)) {
                                                val.filter.push(option.value);
                                            }
                                        } else if (val.filter.includes(option.value)) {
                                            val.filter.splice(index, 1);
                                        }
                                    } else if (option.value === 'date_range') {
                                        onPushEvent('choose_date_range');
                                        setTimeRangeModalOpen(true);
                                        if (val.dateRange.type !== 'all') {
                                            if (!val.filter.includes(option.value)) {
                                                val.filter.push(option.value);
                                            }
                                        } else if (val.filter.includes(option.value)) {
                                            val.filter.splice(index, 1);
                                        }
                                    } else if (index > -1) {
                                        val.filter.splice(index, 1);
                                    } else {
                                        val.filter.push(option.value);
                                        onPushEvent(`filter_by_${option.value}`);
                                    }
                                    onChange(val);
                                }}
                            />
                        );
                    })}
                </Box>
                {/* <Typography sx={{ marginBottom: '10px', fontWeight: 500 }}>{t('Number of tables per view')}</Typography>
                <Box>
                    {numberOfTableOptions.map((option) => {
                        const selected = value.numberOfTable === option.value;
                        return (
                            <Chip
                                variant="outlined"
                                label={option.label}
                                style={{
                                    borderRadius: '8px',
                                    margin: '5px',
                                    padding: '3px',
                                    background: selected ? '#F6EBFC' : '#FFFFFF',
                                    borderColor: '#EBECF2',
                                }}
                                clickable
                                onClick={() => {
                                    const val = cloneDeep(value);
                                    val.numberOfTable = option.value;
                                    onChange(val);
                                }}
                            />
                        );
                    })}
                </Box> */}
            </SwipeableDrawer>
            <Drawer open={selectTableModalOpen} anchor="bottom">
                <SelectTables
                    options={tables}
                    onClose={() => {
                        setSelectTableModalOpen(false);
                    }}
                    selectedItems={value.selectedTables}
                    onChange={(v) => {
                        const val = cloneDeep(value);
                        val.selectedTables = v;
                        onChange(val);
                    }}
                />
            </Drawer>
            <Drawer
                PaperProps={{ sx: { borderRadius: '32px 32px 0px 0px' } }}
                open={timeRangeModalOpen}
                anchor="bottom"
                onClose={() => setTimeRangeModalOpen(false)}
            >
                <CommonModal
                    onClose={() => {
                        setTimeRangeModalOpen(false);
                        onPushEvent('choosing_date_range_cancel');
                    }}
                    onConfirm={() => {
                        const val = clone(value);
                        val.dateRange = timeRange;
                        onChange(val);
                        setTimeRangeModalOpen(false);
                    }}
                >
                    {TimeRangeForm({
                        value: timeRange,
                        onChange: (val) => {
                            setTimeRange(val);
                            onPushEvent(`user_use_filter_date_${val.type}`);
                        },
                        options: ['today', 'month', 'all'],
                        timeSelectEnabled: true,
                    })}
                </CommonModal>
            </Drawer>
        </>
    );
};

export default TableViewFilter;
