import { TextField, FormControl, InputLabel, Select, MenuItem, Drawer, Stack, Button } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import { useTranslation } from '@/hooks/translations';
import React, { useState, useMemo, useEffect, CSSProperties } from 'react';
import useDebounce from '@/hooks/useDebounce';
import { useLanguageQuery } from 'next-export-i18n';
import { useUserContext } from '@/contexts/user';
import TimeRangeForm, { ITimeRangeForm } from '@/components/TimeRangeForm';
import TransactionService from '@/services/transaction';
import { onPushEvent } from '@/services/lib/gtm';
import CommonModal from '@/components/CommonModal';

const drawerStyles: CSSProperties = {
    borderRadius: '32px 32px 0px 0px',
};

export const useFilters = (timeRangeModalOpen: boolean, setTimeRangeModalOpen: (val: boolean) => void) => {
    const transactionService = TransactionService.getInstance();
    const { t } = useTranslation('common');
    const { user } = useUserContext();

    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';
    const [selectedStatus, setSelectedStatus] = useState<string>('All');
    const [startDate, setStartDate] = useState<null | undefined | string>(null);
    const [endDate, setEndDate] = useState<null | undefined | string>(null);
    const [tempTimeRange, setTempTimeRange] = useState<ITimeRangeForm>({
        type: 'all',
        from: null,
        to: null,
    });
    const [timeRange, setTimeRange] = useState<ITimeRangeForm>({
        type: 'all',
        from: null,
        to: null,
    });
    const [backendSearchValue, setBackendSearchValue] = useState<string | null>('');

    const [searchTable, setSearchTable] = useState<string | null>('');
    const [searchOrder, setSearchOrder] = useState<string | null>('');
    const debouncedTableSearch = useDebounce(searchTable, 1000);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openOptions, setOpenOptions] = useState(false);
    const [options, setOptions] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const loadingOptions = openOptions && options.length === 0;

    const [timeoutId, setTimeoutId] = useState<any>();

    const handleDebouncedGtmEvent = (eventName: string) => {
        clearTimeout(timeoutId);
        setTimeoutId(
            setTimeout(() => {
                onPushEvent(eventName);
            }, 1000),
        );
    };

    const filterMenu = useMemo(
        () => [
            {
                id: 'all',
                label: t('All'),
                value: 'All',
            },
            {
                id: 'paid',
                label: t('Paid'),
                value: 'Paid',
            },
            {
                id: 'partially',
                label: t('Partially Paid'),
                value: 'Partially',
            },
            {
                id: 'voucher',
                label: t('Voucher'),
                value: 'Voucher',
            },
            {
                id: 'loyalty',
                label: t('Loyalty'),
                value: 'Loyalty',
            },
        ],
        [lang],
    );

    const handleModalClose = () => {
        setTimeRangeModalOpen(false);
        onPushEvent('user_use_filter_date_cancel');
    };

    useEffect(() => {
        if (!openOptions) {
            setOptions([]);
        }
    }, [openOptions]);

    useEffect(() => {
        if (!loadingOptions || !user.restaurantId || user.restaurantId === '') {
            return;
        }
        transactionService
            .orderSearch(user.restaurantId)
            .then((res) => {
                const fetchedOptions = res.data.data.orders;
                setOptions(Object.keys(fetchedOptions)?.map((key) => fetchedOptions[key]) || []);
            })
            .catch(console.log);
    }, [loadingOptions, user]);

    const renderFilters = () => {
        return (
            <>
                <Stack
                    justifyContent="space-between"
                    alignItems="flex-end"
                    spacing={2}
                    sx={{
                        marginBottom: '1rem',
                    }}
                    direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}
                    width={{ xs: '100%', sm: '100%', md: '100%', lg: '100%' }}
                >
                    <TextField
                        size="small"
                        sx={{
                            marginTop: '0.5rem',

                            width: '100%',
                        }}
                        label={t('Table Name')}
                        placeholder={t('Type to search')}
                        name="name"
                        type="text"
                        fullWidth
                        id="search-field"
                        value={searchTable}
                        onChange={(e) => {
                            setSearchTable(e.target.value);
                            handleDebouncedGtmEvent('user_use_search_table_name_on_orders');
                        }}
                    />

                    <TextField
                        size="small"
                        sx={{ width: '100%', marginTop: '0.5rem' }}
                        label={t('Order ID')}
                        InputProps={{
                            startAdornment: <SearchOutlined sx={{ color: '#A9A9A9', marginRight: '0.3rem' }} />,
                            endAdornment: (
                                <Button
                                    onClick={() => {
                                        setSearchOrder(backendSearchValue);
                                        handleDebouncedGtmEvent('user_use_search_order_id_on_orders');
                                    }}
                                    disabled={!backendSearchValue}
                                >
                                    {t('Search')}
                                </Button>
                            ),
                        }}
                        onChange={(ev) => {
                            if (ev.target.value === '') {
                                setSearchOrder(null);
                            }
                            setBackendSearchValue(ev.target.value);
                        }}
                    />

                    <FormControl
                        size="small"
                        sx={{
                            marginTop: '0.5rem',
                            width: '100%',
                        }}
                    >
                        <InputLabel>{t('Status')}</InputLabel>
                        <Select
                            label={t('Status')}
                            value={selectedStatus}
                            onChange={(ev) => {
                                setSelectedStatus(ev.target.value);
                                onPushEvent('user_filter_status');
                            }}
                        >
                            {filterMenu.map((item) => {
                                return (
                                    <MenuItem value={item.value} id={item.id}>
                                        {t(item.label)}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Stack>
                <Drawer
                    PaperProps={{ sx: drawerStyles }}
                    open={timeRangeModalOpen}
                    anchor="bottom"
                    onClose={handleModalClose}
                >
                    <CommonModal
                        onClose={handleModalClose}
                        onConfirm={() => {
                            setTimeRange(tempTimeRange);
                            handleModalClose();
                        }}
                    >
                        <TimeRangeForm
                            value={tempTimeRange}
                            onChange={(val) => {
                                setTempTimeRange(val);
                                onPushEvent(`user_use_filter_date_${val.type}`);
                            }}
                            timeSelectEnabled
                        />
                    </CommonModal>
                </Drawer>
            </>
        );
    };

    return {
        selectedStatus,
        setSelectedStatus,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        searchTable,
        setSearchTable,
        searchOrder,
        setSearchOrder,
        debouncedTableSearch,
        isModalOpen,
        setIsModalOpen,
        openOptions,
        setOpenOptions,
        options,
        setOptions,
        loading,
        setLoading,
        timeRange,
        setTimeRange,
        renderFilters,
    };
};
