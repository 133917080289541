import { Box, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CustomDateTimePicker from '@/components/CustomDateTimePicker';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from '@/hooks/translations';
// eslint-disable-next-line import/no-duplicates
import { tr, enAU, pt, ja, ar, zhCN, es } from 'date-fns/locale';
import {
    startOfYesterday,
    endOfYesterday,
    endOfDay,
    endOfMonth,
    startOfDay,
    startOfMonth,
    parseISO,
    startOfWeek,
    endOfWeek,
    startOfQuarter,
    endOfQuarter,
    format,
    endOfToday,
    // eslint-disable-next-line import/no-duplicates
} from 'date-fns';
import { useRouter } from 'next/router';

export const formatISOTimezone = (dateISO: string) => {
    const date = parseISO(dateISO);
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
};

const formatISO = (date: Date) => {
    return date.toISOString();
};

export const getDataRangeFromFilter = (
    dateRange: ITimeRangeForm,
): { from: string | null; to: string | null } | null => {
    const date = new Date();
    switch (dateRange.type) {
        case 'custom':
            return {
                from: dateRange.from,
                to: dateRange.to,
            };
        case 'today':
            return {
                from: formatISO(startOfDay(date)),
                to: formatISO(endOfDay(date)),
            };
        case 'month':
            return {
                from: formatISO(startOfMonth(date)),
                to: formatISO(endOfMonth(date)),
            };
        default:
            return null;
    }
};

const parseDate = (date: string) => {
    if (date.toLowerCase().includes('invalid')) {
        return new Date();
    }
    return new Date(date);
};

export type TimeRangeType = 'today' | 'yesterday' | 'week' | 'month' | 'last30Days' | 'quarter' | 'all' | 'custom';

export enum TimeRangeTypes {
    'today' = 'Today',
    'yesterday' = 'Yesterday',
    'week' = 'This Week',
    'month' = 'This Month',
    'last30Days' = 'Last 30 Days',
    'quarter' = 'This Quarter',
    'all' = 'All',
    'custom' = 'Custom',
}

export interface ITimeRangeForm {
    type: TimeRangeType;
    from: string | null;
    to: string | null;
}

export interface IOption {
    name: string;
    title: string;
    dateString: string;
}

interface IProps {
    options?: string[];
    timeSelectEnabled?: boolean;
    minDate?: Date;
    value?: ITimeRangeForm;
    onChange?: (value: ITimeRangeForm) => void;
}
export const getLocaleLang = (lang: string) => {
    switch (lang) {
        case 'tr':
            return tr;
        case 'pt':
            return pt;
        case 'ja':
            return ja;
        case 'ar':
            return ar;
        case 'zh':
            return zhCN;
        case 'es':
            return es;
        default:
            return enAU;
    }
};

const TimeRangeForm = ({ value, onChange, options, minDate, timeSelectEnabled, ...props }: IProps) => {
    const { t } = useTranslation('common');
    const router = useRouter();
    const lang: string = router.query.lang as string;
    const [timeRange, setTimeRange] = useState<TimeRangeType>(value?.type || 'week');
    const [from, setFrom] = useState<Date | null>(value?.from ? parseDate(value.from) : null);
    const [to, setTo] = useState<Date | null>(value?.to ? parseDate(value.to) : null);
    const init = useRef(false);

    const toRef = useRef<HTMLBodyElement>();

    const defaultOptions = ['today', 'month', 'all'];

    const timeRangeOptions = useMemo(() => {
        const date = new Date();
        const rangeOptions: IOption[] = [];

        const getRangeOption = (rangeName: string) => {
            switch (rangeName) {
                case 'today':
                    return {
                        name: rangeName,
                        title: t('Today'),
                        dateString: format(date, 'LLLL dd', { locale: getLocaleLang(lang) }),
                    };
                case 'yesterday':
                    return {
                        name: rangeName,
                        title: t('Yesterday'),
                        dateString: format(startOfYesterday(), 'LLLL dd', { locale: getLocaleLang(lang) }),
                    };
                case 'week':
                    return {
                        name: 'week',
                        title: t('This Week'),
                        dateString: `${format(startOfWeek(date, { weekStartsOn: 1 }), 'LLLL dd', {
                            locale: getLocaleLang(lang),
                        })}-${format(endOfWeek(date, { weekStartsOn: 1 }), 'LLLL dd', {
                            locale: getLocaleLang(lang),
                        })}`,
                    };
                case 'month':
                    return {
                        name: 'month',
                        title: t('This Month'),
                        dateString: `${format(startOfMonth(date), 'LLLL dd', { locale: getLocaleLang(lang) })}-${format(
                            endOfMonth(date),
                            'LLLL dd',
                            {
                                locale: getLocaleLang(lang),
                            },
                        )}`,
                    };
                case 'last30Days':
                    return {
                        name: 'last30Days',
                        title: t('Last 30 Days'),
                        dateString: `${format(
                            new Date(date.getFullYear(), date.getMonth(), date.getDate() - 30),
                            'LLLL dd',
                            { locale: getLocaleLang(lang) },
                        )}-${format(endOfToday(), 'LLLL dd', {
                            locale: getLocaleLang(lang),
                        })}`,
                    };
                case 'quarter':
                    return {
                        name: 'quarter',
                        title: t('This Quarter'),
                        dateString: `${format(startOfQuarter(date), 'LLLL dd', {
                            locale: getLocaleLang(lang),
                        })}-${format(endOfQuarter(date), 'LLLL dd', { locale: getLocaleLang(lang) })}`,
                    };
                case 'all':
                    return {
                        name: 'all',
                        title: t('All'),
                        dateString: '',
                    };
                default:
                    return {
                        name: 'today',
                        title: t('Today'),
                        dateString: format(date, 'dd MMM yyyy', { locale: getLocaleLang(lang) }),
                    };
            }
        };
        (options || defaultOptions)?.forEach((option) => {
            rangeOptions.push(getRangeOption(option));
        });

        return rangeOptions;
    }, [router?.query?.lang, options]);

    useEffect(() => {
        const date = new Date();
        setTimeRange(value?.type || 'today');
        setFrom(value?.from ? parseDate(value.from) : startOfDay(date));
        setTo(value?.to ? parseDate(value.to) : endOfDay(date));
    }, [value]);

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            return;
        }

        onChange?.({
            type: timeRange,
            from: from ? (timeSelectEnabled ? formatISO(from) : formatISO(startOfDay(from))) : null,
            to: to ? (timeSelectEnabled ? formatISO(to) : formatISO(endOfDay(to))) : null,
        });
    }, [timeRange, from?.toString(), to?.toString()]);

    const timeRangeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = (e.target as HTMLInputElement).value as TimeRangeType;
        setTimeRange(val);
        const date = new Date();
        switch (val) {
            case 'today':
                setFrom(startOfDay(date));
                setTo(endOfDay(date));
                break;
            case 'yesterday':
                setFrom(startOfYesterday());
                setTo(endOfYesterday());
                break;
            case 'week':
                setFrom(startOfWeek(date, { weekStartsOn: 1 }));
                setTo(endOfWeek(date, { weekStartsOn: 1 }));
                break;
            case 'month':
                setFrom(startOfMonth(date));
                setTo(endOfMonth(date));
                break;
            case 'quarter':
                setFrom(startOfQuarter(date));
                setTo(endOfQuarter(date));
                break;
            case 'last30Days':
                setFrom(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 30));
                setTo(endOfToday());
                break;
            default:
                break;
        }
    };

    const customChangeHandler = (name: string) => (rangeDate: Date | null) => {
        if (name === 'from' && toRef.current) {
            toRef.current.click();
            setFrom(rangeDate);
        } else if (name === 'to') {
            setTo(rangeDate);
        }
    };

    return (
        <FormControl>
            <FormLabel>{t('Date Range')}</FormLabel>
            <RadioGroup value={timeRange} onChange={timeRangeChangeHandler}>
                <Grid container spacing={1}>
                    {timeRangeOptions?.map((option, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={6}>
                                <FormControlLabel value={option.name} control={<Radio />} label={t(option.title)} />
                            </Grid>
                            <Grid item xs={6} display="flex" alignItems="center" justifyContent="flex-end">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                    }}
                                >
                                    {option.dateString}
                                </Box>
                            </Grid>
                        </React.Fragment>
                    ))}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid item xs={12} sm={2}>
                            <FormControlLabel value="custom" control={<Radio />} label={t('Custom')} />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <CustomDateTimePicker
                                value={value?.from ? new Date(value?.from) : new Date()}
                                timeSelectEnabled={timeSelectEnabled}
                                onChange={customChangeHandler('from')}
                                disabled={value?.type !== 'custom'}
                                textFieldLabel={t('From')}
                                modalTitle={t('Select the first date')}
                                minDate={minDate}
                                fromTo="from"
                                {...props}
                            />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <CustomDateTimePicker
                                value={value?.to ? new Date(value?.to) : new Date()}
                                timeSelectEnabled={timeSelectEnabled}
                                onChange={customChangeHandler('to')}
                                disabled={value?.type !== 'custom'}
                                textFieldLabel={t('To')}
                                modalTitle={t('Select the last date')}
                                minDate={from ?? minDate}
                                fromTo="to"
                                toRef={toRef}
                                {...props}
                            />
                        </Grid>
                    </LocalizationProvider>
                </Grid>
            </RadioGroup>
        </FormControl>
    );
};

export default TimeRangeForm;
