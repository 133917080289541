import { Button, Checkbox, Divider, FormControlLabel, Grid, MenuItem, TextField } from '@mui/material';
import { useTranslation } from '@/hooks/translations';
import {
    DatePresentationEnum,
    IRestaurant,
    IVendorQsrOrderConfig,
    QSRPrintLayoutEnum,
    QSRPrintSizeEnum,
} from '@/views/OrdersTableView/types';
import { kDefaults } from '@/services/utils/merge';
import { useRestaurantContext } from '@/contexts/restaurant';
import React, { useEffect, useMemo, useState } from 'react';
import { parseJSON } from '@/components/TicketModal/utils';

export const parsePrinterConfig = (config: IVendorQsrOrderConfig): any => {
    const { printerSize, ...rest } = config;
    const printerSizeMapArray = parseJSON(printerSize) as any[];
    if (printerSizeMapArray?.length) {
        return {
            ...rest,
            printerSize: printerSizeMapArray?.reduce((a, { key, value }) => {
                a[key] = value;
                return a;
            }, {}),
        };
    }
    return { ...rest };
};

export const transformPrinterConfig = (config: IVendorQsrOrderConfig): any => {
    const { printerSize, ...rest } = config;
    if (printerSize) {
        return {
            ...rest,
            printerSize: JSON.stringify(
                Object.entries(printerSize || {}).map(([key, value]) => {
                    return {
                        key,
                        value,
                    };
                }),
            ),
        };
    }
    return { ...rest };
};

export const getPrinterConfig = (vendor?: IRestaurant | null): IVendorQsrOrderConfig => {
    const def: IVendorQsrOrderConfig = {
        printerPort: '8043',
        printerDeviceId: 'local_printer',
        printerEncrypt: true,
        printerAutoPrintStatus: ['pending'],
        printerDisableAutoPrint: false,
        printerLayout: [QSRPrintLayoutEnum.Kitchen, QSRPrintLayoutEnum.Cashier],
        printerSize: {
            [QSRPrintLayoutEnum.Kitchen]: QSRPrintSizeEnum.Normal,
            [QSRPrintLayoutEnum.Cashier]: QSRPrintSizeEnum.Normal,
        },
        vendorDatePresentation: DatePresentationEnum.Relative,
    };
    const data = kDefaults(parsePrinterConfig(vendor?.order_config || {}), def);
    return {
        ...data,
        printerAutoPrintStatus:
            typeof data.printerAutoPrintStatus === 'string'
                ? [data.printerAutoPrintStatus]
                : data.printerAutoPrintStatus,
        printerLayout: typeof data.printerLayout === 'string' ? [data.printerLayout] : data.printerLayout,
    };
};

export enum PosPrintModeEnum {
    QSR = 'qsr',
    PrinterOnly = 'printerOnly',
}

interface IProps {
    mode?: PosPrintModeEnum;
    onChange: (config: IVendorQsrOrderConfig, active: boolean) => void;
}

const QsrOrderSettings = ({ onChange, mode }: IProps) => {
    const { t } = useTranslation('common');
    const { restaurant } = useRestaurantContext();
    const [config, setConfig] = useState<IVendorQsrOrderConfig>(getPrinterConfig());
    const [active, setActive] = useState(sessionStorage.getItem('qlub.printer.active') === 'true');

    useEffect(() => {
        if (!restaurant) {
            return;
        }

        setConfig(getPrinterConfig(restaurant));
    }, [restaurant]);

    useEffect(() => {
        onChange(config, active);
    }, [config]);

    useEffect(() => {
        sessionStorage.setItem(
            'qlub.printer.active',
            active || mode === PosPrintModeEnum.PrinterOnly ? 'true' : 'false',
        );
    }, [active, mode]);

    const printStatusList = useMemo(
        () => [
            { value: 'pending', label: t('Pending') },
            { value: 'approved', label: t('Approved') },
            {
                value: 'closed',
                label: t('Closed'),
            },
        ],
        [t],
    );

    const printLayoutList = useMemo(
        () => [
            {
                value: QSRPrintLayoutEnum.Kitchen,
                label: t('Kitchen'),
            },
            { value: QSRPrintLayoutEnum.Cashier, label: t('Cashier') },
        ],
        [t],
    );

    const printEncodingList = useMemo(
        () => [
            { value: '', label: t('Default') },
            { value: 'mul', label: t('Multi Language') },
            { value: 'zh-cn', label: t('Simplified Chinese') },
            { value: 'zh-tw', label: t('Traditional Chinese') },
        ],
        [t],
    );

    const printSizeList = useMemo(
        () => [
            {
                value: QSRPrintSizeEnum.Normal,
                label: t('Normal'),
            },
            { value: QSRPrintSizeEnum.Large, label: t('Large') },
        ],
        [t],
    );

    const getPrinterName = (printerType: QSRPrintLayoutEnum) => {
        switch (printerType) {
            case QSRPrintLayoutEnum.Cashier:
                return t('Cashier');
            case QSRPrintLayoutEnum.Kitchen:
                return t('Kitchen');
            default:
                return '';
        }
    };

    const dateFormatList = useMemo(
        () => [
            {
                value: DatePresentationEnum.Relative,
                label: t('Relative'),
            },
            { value: DatePresentationEnum.Full, label: t('Full') },
        ],
        [t],
    );

    return (
        <Grid container spacing={2} sx={{ marginTop: '12px' }}>
            <Grid item xs={4}>
                <TextField
                    label={t('IP')}
                    fullWidth
                    size="small"
                    value={config.printerIP}
                    onChange={(e) => {
                        setConfig((o) => {
                            return {
                                ...o,
                                printerIP: e.target.value,
                            };
                        });
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    label={t('Port')}
                    fullWidth
                    size="small"
                    value={config.printerPort}
                    onChange={(e) => {
                        setConfig((o) => {
                            return {
                                ...o,
                                printerPort: e.target.value,
                            };
                        });
                    }}
                />
            </Grid>
            <Grid item xs={2}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={config.printerEncrypt}
                            onChange={(e) => {
                                setConfig((o) => {
                                    return {
                                        ...o,
                                        printerEncrypt: Boolean(e.target.checked),
                                    };
                                });
                            }}
                        />
                    }
                    label={t('SSL')}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    label={t('Device ID')}
                    fullWidth
                    size="small"
                    value={config.printerDeviceId}
                    onChange={(e) => {
                        setConfig((o) => {
                            return {
                                ...o,
                                printerDeviceId: e.target.value,
                            };
                        });
                    }}
                />
            </Grid>
            {config.printerEncrypt && (
                <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        target="_blank"
                        href={`https://${config.printerIP}:${config.printerPort}/cgi-bin/epos/service.cgi?devid=${config.printerDeviceId}&timeout=10000`}
                        fullWidth
                    >
                        {t('Open the printer')}
                    </Button>
                </Grid>
            )}
            {mode !== PosPrintModeEnum.PrinterOnly && (
                <Grid item xs={6}>
                    <TextField
                        label={t('Print Status')}
                        fullWidth
                        select
                        size="small"
                        SelectProps={{
                            multiple: true,
                        }}
                        value={config.printerAutoPrintStatus}
                        onChange={(e) => {
                            setConfig((o) => {
                                return {
                                    ...o,
                                    printerAutoPrintStatus: (e.target.value || []) as string[],
                                };
                            });
                        }}
                    >
                        {printStatusList.map((item) => {
                            return (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </Grid>
            )}
            {mode !== PosPrintModeEnum.PrinterOnly && (
                <Grid item xs={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={config.printerDisableAutoPrint}
                                onChange={(e) => {
                                    setConfig((o) => {
                                        return {
                                            ...o,
                                            printerDisableAutoPrint: Boolean(e.target.checked),
                                        };
                                    });
                                }}
                            />
                        }
                        label={t('Disable Auto Print')}
                    />
                </Grid>
            )}
            {mode !== PosPrintModeEnum.PrinterOnly && (
                <Grid item xs={8}>
                    <TextField
                        label={t('Print Layout')}
                        fullWidth
                        select
                        size="small"
                        SelectProps={{
                            multiple: true,
                        }}
                        value={config.printerLayout}
                        onChange={(e) => {
                            setConfig((o) => {
                                return {
                                    ...o,
                                    printerLayout: (e.target.value || []) as QSRPrintLayoutEnum[],
                                };
                            });
                        }}
                    >
                        {printLayoutList.map((item) => {
                            return (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </Grid>
            )}
            {mode !== PosPrintModeEnum.PrinterOnly && (
                <Grid item xs={4}>
                    <TextField
                        label={t('Print Encoding')}
                        fullWidth
                        select
                        size="small"
                        value={config.printerEncoding}
                        onChange={(e) => {
                            setConfig((o) => {
                                return {
                                    ...o,
                                    printerEncoding: (e.target.value || '') as string,
                                };
                            });
                        }}
                    >
                        {printEncodingList.map((item) => {
                            return (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </Grid>
            )}
            {mode !== PosPrintModeEnum.PrinterOnly &&
                [QSRPrintLayoutEnum.Cashier, QSRPrintLayoutEnum.Kitchen].map((type) => {
                    return (
                        <Grid key={type} item xs={6}>
                            <TextField
                                label={t('Print Size ({{name}})', { name: getPrinterName(type) })}
                                fullWidth
                                select
                                size="small"
                                value={config.printerSize?.[type] || QSRPrintSizeEnum.Normal}
                                onChange={(e) => {
                                    setConfig((o) => {
                                        return {
                                            ...o,
                                            printerSize: {
                                                ...o.printerSize,
                                                [type]: e.target.value as QSRPrintSizeEnum,
                                            },
                                        };
                                    });
                                }}
                            >
                                {printSizeList.map((item) => {
                                    return (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </Grid>
                    );
                })}
            {mode !== PosPrintModeEnum.PrinterOnly && (
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={active}
                                onChange={(e) => {
                                    setActive(e.target.checked);
                                }}
                            />
                        }
                        label={t('Activate the printer')}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={t('Date Format')}
                    fullWidth
                    select
                    size="small"
                    value={config.vendorDatePresentation || DatePresentationEnum.Relative}
                    onChange={(e) => {
                        setConfig((o) => {
                            return {
                                ...o,
                                vendorDatePresentation: e.target.value as DatePresentationEnum,
                            };
                        });
                    }}
                >
                    {dateFormatList.map((item) => {
                        return (
                            <MenuItem key={item.value} value={item.value}>
                                {item.label}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </Grid>
        </Grid>
    );
};

export default QsrOrderSettings;
