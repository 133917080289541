import { ReactNode } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import { Breakpoint } from '@mui/system';
import { useTranslation } from '@/hooks/translations';
import { CloseOutlined } from '@mui/icons-material';
import { PrimaryButton } from './common';

type CustomModalProps = {
    open: boolean;
    onClose: () => void;
    title?: string;
    actionText?: string;
    actionText2?: string;
    children: ReactNode;
    maxWidth?: Breakpoint | false;
    actionMethod?: () => void;
    actionMethod2?: () => void;
    disableButton?: boolean;
    isFullScreen?: boolean;
};

export default function CustomModal({
    open,
    onClose,
    title,
    actionText,
    actionText2,
    children,
    actionMethod,
    actionMethod2,
    maxWidth,
    disableButton,
    isFullScreen,
}: CustomModalProps) {
    const { t } = useTranslation('common');
    return (
        <Dialog onClose={onClose} open={open} fullWidth maxWidth={maxWidth || 'md'} fullScreen={isFullScreen}>
            {!!title && (
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '30px' }}>
                    {title}
                    {isFullScreen && (
                        <IconButton onClick={onClose}>
                            <CloseOutlined />
                        </IconButton>
                    )}
                </DialogTitle>
            )}
            <DialogContent>{children}</DialogContent>

            <DialogActions
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: ' 1rem 1.6rem 1.6rem 1.6rem',
                    backgroundColor: '#F8F8F8',
                }}
            >
                <PrimaryButton
                    id="cancel-btn"
                    styles={{ backgroundColor: '#C4C4C4' }}
                    color="grey"
                    onClick={onClose}
                    text={t('Cancel')}
                />
                {!!actionText && (
                    <PrimaryButton id="action-btn" onClick={actionMethod} text={actionText} disabled={disableButton} />
                )}
                {!!actionText2 && (
                    <PrimaryButton
                        id="action-btn2"
                        onClick={actionMethod2}
                        text={actionText2}
                        disabled={disableButton}
                    />
                )}
            </DialogActions>
        </Dialog>
    );
}
