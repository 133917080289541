import { IQsrOrderNotification } from '@/views/QsrOrders/types';
import { MenuCategoryResponse, MenuProductResponse } from '@/services/menuService/types';
import { QSRPrintLayoutEnum, QSRPrintSizeEnum, IRestaurant } from '@/views/OrdersTableView/types';
import { EpsonPrinterService } from '@/components/PosPrinteCenter/Printers/Epson';
import { useTranslation } from '@/hooks/translations';
import { OrderRows } from '@/components/PosPrinteCenter/Layouts/Common';

interface IProps {
    vendor: IRestaurant | null;
    order: IQsrOrderNotification;
    productMap: { [key: string]: MenuProductResponse };
    categoryMap: { [key: string]: MenuCategoryResponse };
    currencyCode: string;
    currencySymbol: string;
    size?: QSRPrintSizeEnum;
    itemSize?: QSRPrintSizeEnum;
    encoding?: string;
    onDone: (order: IQsrOrderNotification, layout?: QSRPrintLayoutEnum) => void;
}

export const LayoutCashier = ({
    order,
    productMap,
    categoryMap,
    currencyCode,
    currencySymbol,
    size,
    itemSize,
    encoding,
    onDone,
}: IProps) => {
    const { t } = useTranslation('common');
    const refHandler = (ref: any | undefined) => {
        if (!ref) {
            return;
        }

        EpsonPrinterService.getInstance().print(ref);
        onDone?.(order, QSRPrintLayoutEnum.Cashier);
    };
    return (
        <table
            ref={refHandler}
            data-id={order.id}
            data-type="cashier"
            style={{ border: '1px solid #000', display: 'none' }}
            data-size={size}
            data-currency={currencySymbol.length > 1 ? 'large' : 'normal'}
            data-encoding={encoding}
        >
            {order.ticketId && (
                <tr data-mode="header" data-align="center" data-size={itemSize}>
                    <td colSpan={2}>{t('Ticket: {{id}}', { id: order.ticketId })}</td>
                </tr>
            )}
            {order.urlData.tableId && (
                <tr data-mode="header">
                    <td colSpan={2}>
                        {order.urlData.tableId.indexOf('-') === 0
                            ? t('Pager: {{id}}', { id: order.urlData.tableId.slice(1) })
                            : t('Table: {{id}}', { id: order.urlData.tableId })}
                    </td>
                </tr>
            )}
            {order.userInfo?.name && (
                <tr data-mode="normal">
                    <td>{t('Customer Name: {{name}}', { name: order.userInfo?.name || '' })}</td>
                </tr>
            )}
            {order.date && (
                <tr data-mode="header">
                    <td colSpan={3}>{t('Date: {{date}}', { date: order.date })}</td>
                </tr>
            )}
            <tr data-mode="divider">
                <td colSpan={3} />
            </tr>
            <OrderRows
                items={order.orderData.items}
                productMap={productMap}
                categoryMap={categoryMap}
                currencyCode={currencyCode}
                currencySymbol={currencySymbol}
                indent={0}
                hideIndex
                xQty
                size={itemSize}
            />
            <tr data-mode="divider">
                <td colSpan={3} />
            </tr>
            {order.subTotalStr && (
                <tr data-mode="normal">
                    <td>{t('Subtotal:')}</td>
                    <td />
                    <td>{order.subTotalStr}</td>
                </tr>
            )}
            {order.additives?.map((item) => {
                return (
                    <tr data-mode="normal">
                        <td>{item.title}</td>
                        <td />
                        <td>{`${currencySymbol} ${item.value}`}</td>
                    </tr>
                );
            })}
            <tr data-mode="normal">
                <td>{t('Total:')}</td>
                <td />
                <td>{order.totalStr}</td>
            </tr>
        </table>
    );
};
