import { Fragment } from 'react';
import { useLanguageQuery } from 'next-export-i18n';

import { useTranslation } from '@/hooks/translations';
import { getCurrencyWithAmount, getGstType } from '@/common/utility';
import { IPaymentDetailsResponse } from '@/components/InvoiceTable/types';
import { MultiLocale } from '@/components/TicketModal/utils';
import { EpsonPrinterService } from '@/components/PosPrinteCenter/Printers/Epson';
import { IRestaurant } from '@/views/OrdersTableView/types';

interface IProps {
    vendor: IRestaurant | null;
    order: IPaymentDetailsResponse;
    currencyCode: string;
    currencySymbol: string;
    onDone: (order: IPaymentDetailsResponse) => void;
}

export const LayoutQRInvoice = ({ order, vendor, currencyCode, currencySymbol, onDone }: IProps) => {
    const { t } = useTranslation('common');
    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';

    const refHandler = (ref: any | undefined) => {
        if (!ref) {
            return;
        }

        EpsonPrinterService.getInstance().print(ref);
        onDone?.(order);
    };
    return (
        <table
            ref={refHandler}
            data-id={order.dsi}
            data-type="qr-invoice"
            style={{ border: '1px solid #000', display: 'none' }}
        >
            {vendor?.config?.legalBusinessName ? (
                <tr data-mode="header" data-align="center">
                    <td colSpan={3}>
                        <MultiLocale value={vendor?.config?.legalBusinessName || ''} lang={lang} />
                    </td>
                </tr>
            ) : (
                (vendor?.title || vendor?.name) && (
                    <tr data-mode="header" data-align="center">
                        <td colSpan={3}>{vendor?.config?.legalBusinessName || vendor?.title || vendor?.name}</td>
                    </tr>
                )
            )}

            {vendor?.address1 && (
                <tr data-mode="header" data-align="center">
                    <td colSpan={3}>{vendor?.address1}</td>
                </tr>
            )}
            {vendor?.address2 && (
                <tr data-mode="header" data-align="center">
                    <td colSpan={3}>{vendor?.address2}</td>
                </tr>
            )}

            {order?.url && (
                <>
                    <tr data-mode="normal" />
                    <tr data-mode="qr" data-qr={order?.url} />
                    <tr data-mode="normal" />
                    <tr data-mode="normal" data-align="center">
                        <td colSpan={3}>{t(' Scan QR Code to pay!')}</td>
                    </tr>
                    <tr data-mode="divider">
                        <td colSpan={3} />
                    </tr>
                </>
            )}

            {/* {vendor?.logo_big_url && ( */}
            {/*    <tr data-mode="logo" data-src={vendor?.logo_big_url}> */}
            {/*        <td colSpan={3} /> */}
            {/*    </tr> */}
            {/* )} */}

            {vendor?.restaurant_country?.code === 'au' && (
                <>
                    <tr data-mode="normal" data-align="center">
                        <td colSpan={3}>{t('     TAX INVOICE')}</td>
                    </tr>
                    <tr data-mode="normal" />
                </>
            )}

            {vendor?.config?.abn && (
                <tr data-mode="header">
                    <td colSpan={3}>
                        {vendor?.restaurant_country?.code === 'au' ? t('ABN:') : t('Tax Invoice: ')}
                        <MultiLocale value={String(vendor?.config?.abn || '')} lang={lang} />
                    </td>
                </tr>
            )}
            {vendor?.config?.vatRegistrationNumber && (
                <tr data-mode="header">
                    <td colSpan={3}>
                        {t(`${getGstType(vendor?.country_code)}: {{vatRegistrationNumber}}`, {
                            vatRegistrationNumber: vendor?.config?.vatRegistrationNumber || '',
                        })}
                    </td>
                </tr>
            )}
            {order.tableName && (
                <tr data-mode="header">
                    <td colSpan={3}>{t('Table: {{id}}', { id: order.tableName })}</td>
                </tr>
            )}
            <tr data-mode="divider">
                <td colSpan={3} />
            </tr>
            {order.orderItems?.map((item, idx) => {
                return (
                    <Fragment key={`${idx}`}>
                        <tr data-indent="0" data-mode="normal">
                            <td>
                                {idx + 1} - {item.title}
                            </td>
                            <td>{item.qty}</td>
                            <td align="right">
                                {getCurrencyWithAmount(item.finalPrice, currencySymbol, currencyCode, true)}
                            </td>
                        </tr>
                        {item.additives?.map((additive, idx2) => {
                            return (
                                <tr data-indent="1" data-mode="sub">
                                    <td>{` ${idx2 + 1} - ${additive.title}`}</td>
                                    <td>{item.qty}</td>
                                    <td align="right">
                                        {getCurrencyWithAmount(additive.amount, currencySymbol, currencyCode, true)}
                                    </td>
                                </tr>
                            );
                        })}
                    </Fragment>
                );
            })}
            <tr data-mode="divider">
                <td colSpan={3} />
            </tr>
            {order.bill.subTotal && (
                <tr data-mode="normal">
                    <td>{t('Subtotal:')}</td>
                    <td />
                    <td>{`${currencySymbol} ${order.bill.subTotal}`}</td>
                </tr>
            )}
            {order.bill.additives?.map((item) => {
                return (
                    <tr data-mode="normal">
                        <td>
                            {item.title === '_VAT' && vendor?.restaurant_country?.code === 'au'
                                ? 'GST'
                                : vendor?.restaurant_country?.code === 'ae'
                                ? 'TRN'
                                : item.title}
                        </td>
                        <td />
                        <td>{`${currencySymbol} ${item.value}`}</td>
                    </tr>
                );
            })}
            <tr data-mode="normal">
                <td>{t('Total:')}</td>
                <td />
                <td>{`${currencySymbol} ${order.bill.payableAmount}`}</td>
            </tr>
            {
                <tr data-mode="divider">
                    <td colSpan={3} />
                </tr>
            }
        </table>
    );
};
