import { useTranslation as useTranslationStatic } from 'next-export-i18n';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useTranslation = (ns?: string) => {
    const { t } = useTranslationStatic();

    return {
        t,
    };
};
