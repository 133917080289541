import { ITransaction } from '@/views/Payments';
import { PaymentStatus } from '@/services/utils/order';
import { OrderStatusEnum } from '@/views/QsrOrders/types';
import { TimeRangeType } from '@/components/TimeRangeForm';
import { IQrRow } from '../QRCode/types';

export enum IDinerFeeOption {
    HideAll = 'hideAll',
    HideDashboard = 'hideDashboard',
    HideOrders = 'hideOrders',
    HidePayments = 'hidePayments',
    HideTableView = 'hideTableView',
}

interface IConfig {
    previewBill: boolean;
    vendorSortKeys: string[];
    vendorOrderKeys: string[];
    vendorPaymentKeys: string[];
    vendorShowBestsellingItems: boolean;
    dashboardTooltipEnabled: boolean;
    receiptBuilderEnabled: boolean;
    isDashboardRefundCardVisible: boolean;
    isVendorPaymentDateFilterEnabled: boolean;
    enableShiftMaskforVendorAnalyst: boolean;
    switchLanguageByFlag: boolean;
    abn?: string;
    openingHour?: string;
    closingHour?: string;
    legalBusinessName?: string;
    vatRegistrationNumber?: string;
    receiptBannerTitle?: string;
    receiptBannerDescription?: string;
    vendorPaymentPrint?: boolean;
    vendorQrOnReceipt?: boolean;
    newDashboardEnabled?: boolean;
    selectedDashboardKeys?: string[];
    disableTipForVendorUsers?: boolean;
    qsrTaxManagement?: boolean;
    modifiedTimezone?: string;
    enableBrandScheduler?: boolean;
    vendor_vpos_enable_share?: boolean;
    vendor_enableTipCommission?: boolean;
    enable_payment_link?: boolean;
    view_payment_link_table?: string[];
    enableForexBadge?: boolean;
    enableQrGroup?: boolean;
    vendor_enableReview?: boolean;
    VendorTableViewKeys?: string[];
}

export enum QSRPrintLayoutEnum {
    Cashier = 'cashier',
    Kitchen = 'kitchen',
}

export enum QSRPrintSizeEnum {
    Normal = 'normal',
    Large = 'large',
}

export interface IVendorQsrOrderConfig {
    printerIP?: string;
    printerPort?: string;
    printerEncrypt?: boolean;
    printerDeviceId?: string;
    printerAutoPrintStatus?: string[];
    printerDisableAutoPrint?: boolean;
    printerLayout?: QSRPrintLayoutEnum[];
    printerSize?: { [key: string]: QSRPrintSizeEnum };
    printerEncoding?: string;
    vendorDatePresentation?: DatePresentationEnum;
}

export enum OrderPaymentMethodEnum {
    Default = 'default',
    PayLater = 'payLater',
    NoPayment = 'noPayment',
}

export enum OrderPricePresentationEnum {
    Default = 'default',
    HidePrice = 'hidePrice',
}

export enum DatePresentationEnum {
    Relative = 'relative',
    Full = 'full',
}

export interface IOrderConfig extends IVendorQsrOrderConfig {
    disableEditMenu?: boolean;
    vendorQsrCardViewEnable?: boolean;
    vendorQsrCardViewActions?: OrderStatusEnum[];
    vendorQsrCardViewPendingStatuses?: OrderStatusEnum[];
    vendorQsrCardViewFailedSectionEnable?: boolean;
    vendorQsrCardViewUserInfoField?: string[];
    paymentMethod?: OrderPaymentMethodEnum;
    pricePresentation?: OrderPricePresentationEnum;
    defaultOrderStatus?: OrderStatusEnum;
    multiOrder?: boolean;
    qrPasscodeEnable?: boolean;
    qrDynamicPasscodeEnable?: boolean;
    fetchOrderInterval?: number;
}

export interface ITableParams {
    id: string;
    f1?: any;
    f2?: any;
    hash?: string;
    name?: string;
    url?: string;
}

export interface ICountryConfig {
    code: string;
    currency_code: string;
    currency_symbol: string;
    enabled: boolean;
    iso_code: string;
    language_code: string;
    name: string;
    phone_prefix: string;
    config: {
        compactInvoiceTable: boolean;
        defaultLang: string;
        newEmailServer: boolean;
        showAddressLine2: boolean;
        showArea: boolean;
        showGst: boolean;
        showOrderSubtotal: boolean;
        showTax: boolean;
        showTip: boolean;
        showVat: boolean;
        splitMode: string;
        zendeskKey: string;
        dinerFeeVisibility: IDinerFeeOption[];
        isGoogleCurrencyFormatEnabled: boolean;
        disableTipCardAndChart: boolean;
        newSidebarEnabled: boolean;
        whatsappHelpNumber: string;
        vendor_enableOrderSearch: boolean;
        bookedAmount: number;
        reviewMetricsEnabled: boolean;
    };
}

export enum RestaurantOrderMode {
    Default = 'default',
    DigitalMenu = 'digital_menu',
    QSR = 'qsr',
    Mixed = 'mixed',
}

export interface IRestaurant {
    id: string;
    restaurant_unique: string;
    name: string;
    brand: {
        created_at: string;
        deleted_at: null;
        email: string;
        id: string;
        name: string;
        pos_config: unknown;
        updated_at: string | null;
    };
    brand_id: string;
    phone: string;
    email: string;
    title: string;
    address1: string;
    address2: string;
    area: string;
    city: string;
    country: string;
    external_menu_url: string;
    external_menu_format?: any;
    primary_colour: string;
    secondary_colour: string;
    background_url: string;
    logo_big_url: string;
    logo_small_url: string;
    country_code: string;
    restaurant_pos_access_id: string;
    google_place_id: string;
    ops_mode: string;
    timezone: string;
    timezoneName?: string;
    config: IConfig;
    created_at: Date;
    updated_at: Date;
    deleted_at?: any;
    restaurant_country: null | ICountryConfig;
    restaurant_timezone: null | { timezone: string };
    pos_vendor?: string;
    posAccess?: {
        id: string;
        pos_vendor_data: any;
        posVendor: {
            pos_vendor_name: string;
        };
    };
    order_mode?: RestaurantOrderMode;
    order_config?: IOrderConfig;
    qr_groups?: IQrRow[];
}

export interface ITinyRestaurant {
    id: string;
    country_code: string;
    restaurant_unique: string;
    name: string;
    title: string;
    logo_big_url: string;
    logo_small_url: string;
    pos_vendor_name: string;
}

export interface IRestaurantTables {
    id: string;
    restaurant_id: string;
    version: number;
    url: string;
    params: ITableParams;
    created_at: Date;
    updated_at: Date;
    deleted_at?: any;
    restaurant: IRestaurant;
}

export interface IRequestData {
    page: number;
    limit: number;
    startDate?: string | null;
    endDate?: string | null;
    period?: TimeRangeType;
}

export interface ITableTransaction {
    transaction: ITransaction;
    tip: number;
    paidAmount: number;
    customerCommission: number;
    customerPaid: number;
    paymentStatus: PaymentStatus;
    remaining: number;
    maxDate: Date | null;
    ago: string;
    voucherAmount: number;
    loyaltyAmount: number;
    bookedAmount: number;
}

export interface ITableTransactionExtended extends ITableTransaction {
    url: string;
}

export interface ITableItem {
    table: ITableParams;
    transactions: ITableTransaction[];
}

export interface IGetRestaurantPosRes {
    integrated: boolean;
    branchId?: string;
    paymentId?: string;
    base_url?: string;
    client_id?: string;
    redirect_url?: string;
}

export interface IGetBrandPosRes {
    integrated: boolean;
}

export interface IGetRestaurantPosBranchRes {
    list: IRestaurantPosBranch[];
}

export interface IRestaurantPosBranch {
    id: string;
    title: string;
    address: string;
}

export interface IGetRestaurantPosPaymentRes {
    list: IRestaurantPosPayment[];
}

export interface IRestaurantPosPayment {
    id: string;
    type: number;
    name: string;
}
