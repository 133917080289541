import React, { useEffect, useState } from 'react';
import { Box, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel } from '@mui/material';
import { useTranslation } from '@/hooks/translations';
import TimeRangeForm, { formatISOTimezone, ITimeRangeForm } from '@/components/TimeRangeForm';
import { saveAs } from 'file-saver';
import { cloneDeep } from 'lodash';
import { useRestaurantContext } from '@/contexts/restaurant';
import CustomModal from '@/components/CustomModal';
import { onPushEvent } from '@/services/lib/gtm';
import { endOfDay, formatISO, startOfDay } from 'date-fns';
import MenuService from '@/services/menuService/menuService';
import { useSnackbar } from 'notistack';

interface IProps {
    open: boolean;
    onClose: () => void;
    PickersDay?: React.ComponentType<any>;
}

export default function QsrExportModal(props: IProps) {
    const menuService = MenuService.getInstance();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('common');
    const [exportType, setExportType] = useState<string>('product');
    const [loading, setLoading] = useState(false);
    const date = new Date();

    const [timeRange, setTimeRange] = useState<ITimeRangeForm>({
        type: 'today',
        from: formatISO(startOfDay(date)),
        to: formatISO(endOfDay(date)),
    });
    const [reportFileFormat, setReportFileFormat] = useState<string>('csv');
    const { restaurant } = useRestaurantContext();

    const exportTypeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setExportType((e.target as HTMLInputElement).value);
    };

    const fileTypeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setReportFileFormat((e.target as HTMLInputElement).value);
    };

    const saveFile = (data: Blob, name: string) => {
        const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, name);
    };

    const getDateQuery = () => {
        if (timeRange.type === 'all' || !timeRange.from || !timeRange.to) {
            return {
                startDate: undefined,
                endDate: undefined,
            };
        }

        return {
            startDate: formatISOTimezone(timeRange.from),
            endDate: formatISOTimezone(timeRange.to),
        };
    };

    const closeHandler = () => {
        setExportType('order');
        setTimeRange({
            type: 'today',
            from: formatISO(startOfDay(date)),
            to: formatISO(endOfDay(date)),
        });
        setReportFileFormat('csv');
        setLoading(false);
        props.onClose();
    };

    const exportOrder = () => {
        setLoading(true);
        const { startDate, endDate } = getDateQuery();
        menuService
            .exportOrderReport(restaurant?.id || '', {
                fileFormat: reportFileFormat,
                startDate,
                endDate,
            })
            .then((response) => {
                saveFile(
                    response.data,
                    `order-${restaurant?.country_code}-${restaurant?.restaurant_unique}.${reportFileFormat}`,
                );
            })
            .catch((err) => {
                enqueueSnackbar(t('Item not found'), { variant: 'error' });
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
                closeHandler();
            });
    };

    const exportProduct = () => {
        setLoading(true);
        const { startDate, endDate } = getDateQuery();
        menuService
            .exportProductReport(restaurant?.id || '', {
                fileFormat: reportFileFormat,
                startDate,
                endDate,
            })
            .then((response) => {
                saveFile(
                    response.data,
                    `product-${restaurant?.country_code}-${restaurant?.restaurant_unique}.${reportFileFormat}`,
                );
            })
            .catch((err) => {
                enqueueSnackbar(t('Item not found'), { variant: 'error' });
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
                closeHandler();
            });
    };

    const exportProductDistinct = () => {
        setLoading(true);
        const { startDate, endDate } = getDateQuery();
        menuService
            .exportProductDistinctReport(restaurant?.id || '', {
                fileFormat: reportFileFormat,
                startDate,
                endDate,
            })
            .then((response) => {
                saveFile(
                    response.data,
                    `product-distinct-${restaurant?.country_code}-${restaurant?.restaurant_unique}.${reportFileFormat}`,
                );
            })
            .catch((err) => {
                enqueueSnackbar(t('Item not found'), { variant: 'error' });
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
                closeHandler();
            });
    };

    const getActionMethod = () => {
        onPushEvent('qsr_user_use_export_function');
        switch (exportType) {
            case 'product':
                exportProduct();
                break;
            case 'product-distinct':
                exportProductDistinct();
                break;
            case 'order':
                exportOrder();
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        onPushEvent(`qsr_user_choose_${reportFileFormat}_view_${timeRange}_${exportType}`);
    }, [exportType, timeRange, reportFileFormat]);

    return (
        <CustomModal
            {...props}
            title={t('Export Product Report')}
            actionText={t('Export')}
            actionMethod={getActionMethod}
            maxWidth="sm"
            disableButton={loading}
            onClose={closeHandler}
        >
            <Box marginBottom={2}>
                <FormControl>
                    <FormLabel>{t('Report Type')}</FormLabel>

                    <RadioGroup row value={exportType} onChange={exportTypeChangeHandler} sx={{ display: 'flex' }}>
                        <FormControlLabel value="order" control={<Radio />} label={t('Order')} />
                        <FormControlLabel value="product" control={<Radio />} label={t('Product')} />
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box marginBottom={2}>
                {TimeRangeForm({
                    value: timeRange,
                    onChange: (value) => {
                        setTimeRange(cloneDeep(value));
                    },
                    timeSelectEnabled: true,
                })}
            </Box>
            <Box marginBottom={2}>
                <FormControl>
                    <FormLabel>{t('File Type')}</FormLabel>
                    <RadioGroup row value={reportFileFormat} onChange={fileTypeChangeHandler} sx={{ display: 'flex' }}>
                        <FormControlLabel value="csv" control={<Radio />} label={t('.csv')} />
                        <FormControlLabel value="xlsx" control={<Radio />} label={t('.xlsx')} />
                    </RadioGroup>
                </FormControl>
            </Box>
        </CustomModal>
    );
}
