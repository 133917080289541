import { PageTitle, Section } from '@/components/common';
import { Box, Chip, Grid, LinearProgress, Tab, Tabs } from '@mui/material';
import { useLanguageQuery, useTranslation } from 'next-export-i18n';
import DataTable from 'react-data-table-component';
import { useEffect, useRef, useState } from 'react';
import { covertUtcToRestaurantDateTime, getCurrencyWithAmount } from '@/common/utility';
import QsrOrdersService from '@/services/qsrOrders';
import QsrOrdersDetails from '@/components/QsrOrderDetails';
import KKTablePagination from '@/components/Table/pagination';
import { useRestaurantContext } from '@/contexts/restaurant';
import EventBus from '@/config/event-handler';
import { vendorOrderEvent } from '@/contexts/event.const';
import { useUserContext } from '@/contexts/user';
import { IOrderNotificationEvent } from '@/views/Payments';
import QsrTableView from '@/components/QsrOrders/qsrTableView';
import { cloneDeep, isEqual } from 'lodash';
import RestaurantService from '@/services/restaurant';
import { ITimeRangeForm, TimeRangeTypes } from '@/components/TimeRangeForm';
import { IRestaurant } from '@/views/OrdersTableView/types';
import { IOrderItemStatus } from '@/components/QsrOrderAction';
import { parsePrinterConfig, transformPrinterConfig } from '@/components/PosPrinteCenter/QsrOrderSettings';
import { timeUnix } from '@/services/utils/k_time';
import { useColumns } from './useColumns';
import { IQsrOrder, IQsrOrderNotification, IQsrOrdersResponse, OrderStatusEnum } from './types';
import { useFilters, getIcon } from './useFilters';

export function convertOrderToTableRow(
    orderItem: IQsrOrder,
    lang: string,
    currencyCode: string,
    currencySymbol: string,
): IQsrOrderNotification {
    const date =
        lang !== 'ja'
            ? covertUtcToRestaurantDateTime(orderItem.createdAt, 'MM/DD/YYYY HH:mm a')
            : covertUtcToRestaurantDateTime(orderItem.createdAt, 'YYYY/MM/DD HH:mm');

    return {
        ...orderItem,
        totalStr: getCurrencyWithAmount(orderItem.total, currencyCode, currencySymbol, true),
        subTotalStr: orderItem.subTotal
            ? getCurrencyWithAmount(orderItem.subTotal, currencyCode, currencySymbol, true)
            : undefined,
        date,
    };
}

const transformOrders = (orders: IQsrOrderNotification[], defaultStatus?: OrderStatusEnum) => {
    return orders.map((o) => ({
        ...o,
        orderStatus: o.orderStatus || { name: defaultStatus || OrderStatusEnum.Pending },
    }));
};

const trimOrders = (
    orders: IQsrOrderNotification[],
    statuses: string[],
    notInStatuses: string[],
    defaultStatus?: OrderStatusEnum,
) => {
    if (statuses.length === 0 && notInStatuses.length === 0) {
        return transformOrders(orders, defaultStatus);
    }
    return transformOrders(
        orders.filter((o) => {
            return (
                (statuses.length === 0 || (statuses.length > 0 && statuses.includes(o.orderStatus?.name || ''))) &&
                (notInStatuses.length === 0 ||
                    (notInStatuses.length > 0 && !notInStatuses.includes(o.orderStatus?.name || '')))
            );
        }),
        defaultStatus,
    );
};

export enum QSRCardModeEnum {
    Normal = 'normal',
    Pending = 'pending',
    NotPending = 'notPending',
    Failed = 'failed',
}

interface IProps {
    mode: QSRCardModeEnum;
    restaurant: IRestaurant;
    view: string;
    timeRange: ITimeRangeForm;
    selectedStatus: string[];
    notInSelectedStatus?: string[];
    refId?: string;
    refreshTrigger?: number;
    hide?: boolean;
}

function QsrOrdersInner({
    mode,
    restaurant,
    view,
    timeRange,
    selectedStatus,
    refId,
    refreshTrigger,
    notInSelectedStatus,
    hide,
}: IProps) {
    const { t } = useTranslation('common');
    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';
    const qsrOrdersService = QsrOrdersService.getInstance();
    const { user } = useUserContext();
    const [data, setData] = useState<IQsrOrderNotification[]>([]);
    const [currencySymbol, setCurrencySymbol] = useState<string>('');
    const [currencyCode, setCurrencyCode] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedRow, setSelectedRow] = useState<IQsrOrder | null>(null);
    const columns = useColumns({ setSelectedRow });
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [totalRow, setTotalRow] = useState<number>(0);
    const [more, setMore] = useState(true);
    const lastUpdateRef = useRef(0);

    const tablePageChangeHandler = (p: number) => {
        setPage(p);
    };

    const tableRowsPerPageChangeHandler = (val: number) => {
        setLimit(val);
    };

    const getOrderHandler = (isRefresh?: boolean, noLoading?: boolean) => {
        if (!restaurant) {
            return;
        }

        if (!noLoading) {
            setLoading(true);
        }
        setData([]);
        qsrOrdersService
            .getQsrOrders({
                restaurantId: restaurant.id,
                page: refId ? 0 : page - 1,
                limit,
                refId,
                ...(selectedStatus.length > 0 ? { statuses: selectedStatus } : {}),
                ...(notInSelectedStatus?.length ? { notInStatuses: notInSelectedStatus } : {}),
                ...(timeRange && { startDate: timeRange.from, endDate: timeRange.to }),
            })
            .then((res: IQsrOrdersResponse) => {
                lastUpdateRef.current = timeUnix();
                const orderList =
                    res.orders
                        ?.map((item) =>
                            convertOrderToTableRow(
                                item,
                                lang,
                                res.vendorDetails.currency_symbol,
                                res.vendorDetails.currency_code,
                            ),
                        )
                        .sort((a: any, b: any) => {
                            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                        }) || [];

                setMore(res.orders.length >= limit);
                const defaultOrderStatus = restaurant?.order_config?.defaultOrderStatus;
                if (mode === QSRCardModeEnum.Pending && !refId && !isRefresh) {
                    setData((o) =>
                        trimOrders(
                            [...o, ...orderList],
                            selectedStatus || [],
                            notInSelectedStatus || [],
                            defaultOrderStatus,
                        ),
                    );
                } else {
                    setData(trimOrders(orderList, selectedStatus || [], notInSelectedStatus || [], defaultOrderStatus));
                }
                setCurrencySymbol(res.vendorDetails.currency_symbol);
                setCurrencyCode(res.vendorDetails.currency_code);
                setTotalRow(res?.pagination?.count || 0);
            })
            .catch((err: any) => {
                console.log(err);
            })
            .finally(() => {
                if (!noLoading) {
                    setLoading(false);
                }
            });
    };

    useEffect(() => {
        getOrderHandler();
    }, [restaurant.id, selectedStatus.toString(), timeRange.from, timeRange.to, timeRange.type, page, limit, refId]);

    useEffect(() => {
        if (!refreshTrigger) {
            return;
        }

        getOrderHandler(true);
    }, [refreshTrigger]);

    useEffect(() => {
        setData([]);
        if (!user || (user.userData.role || '').indexOf('Vendor') === -1 || page > 1) {
            return () => {
                //
            };
        }

        const fetchDataHandler = ({ detail: msgList }: IOrderNotificationEvent) => {
            lastUpdateRef.current = timeUnix();
            setData((li) => {
                const tempLi = cloneDeep(li);
                msgList.forEach((msg) => {
                    const order = convertOrderToTableRow(msg, lang, currencySymbol, currencyCode);
                    const idx = tempLi.findIndex((o) => o.id === order.id);
                    if (idx > -1) {
                        tempLi[idx] = { ...tempLi[idx], ...order };
                    } else {
                        tempLi.unshift(order);
                    }
                });
                return trimOrders(
                    tempLi,
                    selectedStatus || [],
                    notInSelectedStatus || [],
                    restaurant?.order_config?.defaultOrderStatus,
                );
            });
        };

        EventBus.on(vendorOrderEvent, fetchDataHandler);
        return () => {
            EventBus.remove(vendorOrderEvent, fetchDataHandler);
        };
    }, [user, page, currencySymbol, currencyCode, lang]);

    useEffect(() => {
        if (!restaurant || Number(restaurant.order_config?.fetchOrderInterval || 0) <= 0) {
            return () => {
                //
            };
        }
        const intervalAmount = Math.max(restaurant?.order_config?.fetchOrderInterval || 0, 10);
        const interval = setInterval(() => {
            if (timeUnix() - lastUpdateRef.current < intervalAmount * 1.5) {
                return;
            }
            console.info('reload with interval');
            getOrderHandler(true, true);
        }, intervalAmount * 1000);
        return () => {
            clearInterval(interval);
        };
    }, [restaurant]);

    const batchChangeHandler = (payload: IOrderItemStatus) => {
        setData((li) => {
            const cloneList = cloneDeep(li);
            const idx = cloneList.findIndex((o) => o.id === payload.id);
            if (idx > -1 && cloneList?.[idx]) {
                cloneList[idx].orderData.items = cloneList[idx].orderData?.items?.map((item) => {
                    if (item.uid === payload.uid) {
                        return {
                            ...item,
                            status: payload.status,
                        };
                    }
                    return item;
                });
            }
            return cloneList;
        });
    };

    const doneHandler = (status: OrderStatusEnum, ticketId: string, referenceId?: string) => {
        setData((li) => {
            const cloneList = cloneDeep(li);
            const idx = cloneList.findIndex((o) => o.refId === referenceId);
            if (idx > -1 && cloneList?.[idx]) {
                cloneList[idx].orderStatus = { name: status };
                cloneList[idx].ticketId = ticketId;
            }
            return cloneList;
        });
    };

    const printHandler = (id: string) => {
        const item = data.find((o) => o.id === id);
        if (!item) {
            return;
        }

        const payload: IQsrOrder[] = [
            {
                ...item,
                force: true,
                silent: true,
            },
        ];
        EventBus.dispatch(vendorOrderEvent, payload);
    };

    const noOrderComponent = () => {
        return (
            <Box
                sx={{
                    width: '100%',
                    paddingRight: '1.2em',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        paddingRight: '1.2em',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '20px',
                    }}
                >
                    {t('No Order Found!')}
                </Box>
                <KKTablePagination rowsPerPage={limit} rowCount={totalRow} currentPage={page} />
            </Box>
        );
    };

    if (hide) {
        return null;
    }

    if (view === 'table') {
        return (
            <Section>
                <DataTable
                    customStyles={{
                        table: {
                            style: {
                                fontFamily: 'Inter',
                            },
                        },
                        rows: {
                            style: {
                                borderBottom: 'none !important',
                            },
                        },
                    }}
                    striped
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationPerPage={limit}
                    paginationTotalRows={totalRow}
                    onChangeRowsPerPage={tableRowsPerPageChangeHandler}
                    onChangePage={tablePageChangeHandler}
                    paginationComponent={KKTablePagination}
                    noDataComponent={noOrderComponent()}
                />
                <QsrOrdersDetails
                    open={Boolean(selectedRow)}
                    data={selectedRow}
                    currencySymbol={currencySymbol}
                    currencyCode={currencyCode}
                    restaurant={restaurant}
                    onClose={() => {
                        setSelectedRow(null);
                    }}
                    onDone={doneHandler}
                />
            </Section>
        );
    }
    return (
        <Grid
            container
            spacing={1}
            sx={{
                padding: mode !== QSRCardModeEnum.Pending ? '0 0 54px' : undefined,
            }}
        >
            {loading && data.length > 0 && (
                <Grid item xs={12}>
                    <LinearProgress variant="indeterminate" />
                </Grid>
            )}
            <Grid item xs={12}>
                {!loading && data.length === 0 && (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '20px',
                        }}
                    >
                        {t('There is no order with selected filters')}
                    </Box>
                )}
                {loading && (mode !== QSRCardModeEnum.Pending || data.length === 0) ? (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '20px',
                        }}
                    >
                        {t('Loading...')}
                    </Box>
                ) : (
                    <QsrTableView
                        data={data}
                        currencySymbol={currencySymbol}
                        currencyCode={currencyCode}
                        restaurant={restaurant}
                        onBatchChange={batchChangeHandler}
                        onDone={doneHandler}
                        onPrint={printHandler}
                        onLoadMore={
                            more && data.length > 0 && mode === QSRCardModeEnum.Pending
                                ? () => {
                                      setPage((p) => p + 1);
                                  }
                                : undefined
                        }
                        loading={loading}
                    />
                )}
            </Grid>
            {mode !== QSRCardModeEnum.Pending && (
                <Grid item xs={12}>
                    <Box
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            backgroundColor: '#fff',
                            padding: '8px 16px',
                        }}
                    >
                        <KKTablePagination
                            rowsPerPage={limit}
                            rowCount={totalRow}
                            currentPage={page}
                            onChangeRowsPerPage={tableRowsPerPageChangeHandler}
                            onChangePage={tablePageChangeHandler}
                        />
                    </Box>
                </Grid>
            )}
        </Grid>
    );
}

export default function QsrOrders() {
    const { t } = useTranslation('common');
    const { restaurant, setRestaurant } = useRestaurantContext();

    const [tab, setTab] = useState<QSRCardModeEnum>(QSRCardModeEnum.Pending);

    const { renderFilters, selectedStatus, timeRange, refId, refreshTrigger, view, config, removeFilter } = useFilters(
        tab,
        restaurant?.order_config?.vendorQsrCardViewPendingStatuses || [],
    );

    useEffect(() => {
        if (isEqual(config, {}) || !restaurant) {
            return;
        }
        if (isEqual(config, parsePrinterConfig(restaurant.order_config || {}))) {
            return;
        }

        RestaurantService.getInstance()
            .editRestaurantOrderConfig(restaurant.id || '', {
                orderConfig: transformPrinterConfig(config),
            })
            .then((res) => {
                setRestaurant(res);
            });
    }, [config]);

    const hasPendingSection = Boolean(restaurant?.order_config?.vendorQsrCardViewPendingStatuses?.length);
    const hasFailedSection = Boolean(restaurant?.order_config?.vendorQsrCardViewFailedSectionEnable);

    useEffect(() => {
        setTab(!hasPendingSection && !hasFailedSection ? QSRCardModeEnum.Normal : QSRCardModeEnum.Pending);
    }, [restaurant]);

    function generateTimeRangeLabel(from: string, to: string) {
        return `From ${new Date(from).getDate()} ${new Date(from).toLocaleDateString('en-US', {
            month: 'short',
        })} to ${new Date(to).getDate()} ${new Date(to).toLocaleDateString('en-US', { month: 'short' })}`;
    }

    function formatTimeRangeType(type: string) {
        return type
            .split(' ')
            .map((word, index) => (index === 0 ? word : word.charAt(0).toLowerCase() + word.slice(1)))
            .join(' ');
    }

    if (!restaurant) {
        return null;
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <PageTitle
                    button={renderFilters()}
                    sx={{
                        paddingBottom: 0,
                    }}
                />
                <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                        overflowX: 'scroll',
                        height: '50px',
                    }}
                >
                    {timeRange.from && timeRange.to && (
                        <Chip
                            {...(timeRange.type !== 'today'
                                ? {
                                      onDelete: () => removeFilter('timeRange'),
                                  }
                                : {})}
                            label={
                                <span>
                                    <b>Filter by:</b>{' '}
                                    {timeRange.type === 'custom'
                                        ? generateTimeRangeLabel(timeRange.from, timeRange.to)
                                        : formatTimeRangeType(TimeRangeTypes[timeRange.type])}
                                </span>
                            }
                            sx={{
                                borderRadius: '8px',
                                margin: '5px',
                                padding: '10px',
                                color: '#1A1C23',
                                background: '#EBECF2',
                                borderColor: '#EBECF2',
                                '& .MuiChip-deleteIcon': {
                                    color: '#7D00D4',
                                    fontSize: '20px',
                                },
                            }}
                        />
                    )}
                    {selectedStatus.length > 0 && (
                        <Chip
                            onDelete={() => {
                                removeFilter('status');
                            }}
                            label={
                                <span>
                                    <b>Status by:</b>{' '}
                                    {`${selectedStatus
                                        .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
                                        .join(', ')} status`}
                                </span>
                            }
                            sx={{
                                borderRadius: '8px',
                                margin: '5px',
                                padding: '10px',
                                color: '#1A1C23',
                                background: '#EBECF2',
                                borderColor: '#EBECF2',
                                '& .MuiChip-deleteIcon': {
                                    color: '#7D00D4',
                                    fontSize: '20px',
                                },
                            }}
                        />
                    )}
                    <Chip
                        icon={getIcon({ value: view }, true)}
                        label={`${view.charAt(0).toUpperCase()}${view.slice(1)} view`}
                        sx={{
                            borderRadius: '8px',
                            margin: '5px',
                            padding: '10px',
                            color: '#1A1C23',
                            background: '#EBECF2',
                            borderColor: '#EBECF2',
                        }}
                    />
                </Box>
            </Grid>
            {(hasPendingSection || hasFailedSection) && (
                <Grid item xs={12}>
                    <Tabs
                        variant="fullWidth"
                        value={tab}
                        onChange={(e, val) => {
                            setTab(val as QSRCardModeEnum);
                        }}
                    >
                        {hasFailedSection && (
                            <Tab value={QSRCardModeEnum.Failed} label={t('Failed Orders')} color="error" />
                        )}
                        <Tab value={QSRCardModeEnum.Pending} label={t('Pending Orders')} />
                        <Tab value={QSRCardModeEnum.NotPending} label={t('All Other Orders')} />
                    </Tabs>
                </Grid>
            )}
            {hasFailedSection && (
                <Grid item xs={12} sx={{ display: tab === QSRCardModeEnum.Failed ? 'block' : 'none' }}>
                    <QsrOrdersInner
                        hide={tab !== QSRCardModeEnum.Failed}
                        mode={QSRCardModeEnum.Failed}
                        restaurant={restaurant}
                        view="table"
                        timeRange={timeRange}
                        selectedStatus={[OrderStatusEnum.Failed]}
                        refId={refId}
                        refreshTrigger={refreshTrigger}
                    />
                </Grid>
            )}
            {hasPendingSection && (
                <Grid item xs={12} sx={{ display: tab === QSRCardModeEnum.Pending ? 'block' : 'none' }}>
                    <QsrOrdersInner
                        hide={tab !== QSRCardModeEnum.Pending}
                        mode={QSRCardModeEnum.Pending}
                        restaurant={restaurant}
                        view={view}
                        timeRange={timeRange}
                        selectedStatus={restaurant.order_config?.vendorQsrCardViewPendingStatuses || []}
                        refId={refId}
                        refreshTrigger={refreshTrigger}
                    />
                </Grid>
            )}
            <Grid
                item
                xs={12}
                sx={{ display: tab === QSRCardModeEnum.NotPending || !hasPendingSection ? 'block' : 'none' }}
            >
                <QsrOrdersInner
                    hide={!(tab === QSRCardModeEnum.NotPending || !hasPendingSection)}
                    mode={hasPendingSection ? QSRCardModeEnum.NotPending : QSRCardModeEnum.Normal}
                    restaurant={restaurant}
                    view={view}
                    timeRange={timeRange}
                    selectedStatus={selectedStatus}
                    notInSelectedStatus={restaurant.order_config?.vendorQsrCardViewPendingStatuses || []}
                    refId={refId}
                    refreshTrigger={refreshTrigger}
                />
            </Grid>
        </Grid>
    );
}
