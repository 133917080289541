import { CSSProperties, useMemo, useState } from 'react';
import { useTranslation } from '@/hooks/translations';
import { useLanguageQuery } from 'next-export-i18n';
import { Button, Drawer, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { onPushEvent } from '@/services/lib/gtm';
import { SearchOutlined } from '@mui/icons-material';
import TimeRangeForm, { ITimeRangeForm } from '@/components/TimeRangeForm';
import CommonModal from '@/components/CommonModal';

const drawerStyles: CSSProperties = {
    borderRadius: '32px 32px 0px 0px',
};

export const useFilters = (timeRangeModalOpen: boolean, setTimeRangeModalOpen: (val: boolean) => void) => {
    const { t } = useTranslation('common');
    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';

    const [searchOrder, setSearchOrder] = useState<string | null>('');
    const [backendSearchValue, setBackendSearchValue] = useState<string | null>('');
    const [selectedStatus, setSelectedStatus] = useState<string>('All');

    const [tempTimeRange, setTempTimeRange] = useState<ITimeRangeForm>({
        type: 'all',
        from: null,
        to: null,
    });
    const [timeRange, setTimeRange] = useState<ITimeRangeForm>({
        type: 'all',
        from: null,
        to: null,
    });

    const filterMenu = useMemo(
        () => [
            {
                id: 'all',
                label: t('All'),
                value: 'All',
            },
            {
                id: 'paid',
                label: t('Paid'),
                value: 'paid',
            },
            {
                id: 'partially',
                label: t('Partially Paid'),
                value: 'partial',
            },
            {
                id: 'refunded',
                label: t('Refunded'),
                value: 'refund',
            },
        ],
        [lang],
    );

    const [timeoutId, setTimeoutId] = useState<any>();

    const handleDebouncedGtmEvent = (eventName: string) => {
        clearTimeout(timeoutId);
        setTimeoutId(
            setTimeout(() => {
                onPushEvent(eventName);
            }, 1000),
        );
    };

    const handleModalClose = () => {
        setTimeRangeModalOpen(false);
        onPushEvent('user_use_filter_date_cancel');
    };

    const renderFilters = () => {
        return (
            <>
                <Stack
                    justifyContent="space-between"
                    alignItems="flex-end"
                    spacing={2}
                    sx={{
                        marginBottom: '1rem',
                    }}
                    direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }}
                    width={{ xs: '100%', sm: '100%', md: '100%', lg: '100%' }}
                >
                    <TextField
                        size="small"
                        sx={{ marginTop: '0.5rem', width: '100%' }}
                        label={t('Order ID')}
                        InputProps={{
                            startAdornment: <SearchOutlined sx={{ color: '#A9A9A9', marginRight: '0.3rem' }} />,
                            endAdornment: (
                                <Button
                                    onClick={() => {
                                        setSearchOrder(backendSearchValue);
                                        handleDebouncedGtmEvent('user_use_search_order_id_on_orders');
                                    }}
                                    disabled={!backendSearchValue}
                                >
                                    {t('Search')}
                                </Button>
                            ),
                        }}
                        onChange={(ev) => {
                            if (ev.target.value === '') {
                                setSearchOrder(null);
                            }
                            setBackendSearchValue(ev.target.value);
                        }}
                    />

                    <FormControl
                        size="small"
                        sx={{
                            marginTop: '0.5rem',
                            width: '100%',
                        }}
                    >
                        <InputLabel>{t('Status')}</InputLabel>
                        <Select
                            label={t('Status')}
                            value={selectedStatus}
                            onChange={(ev) => {
                                setSelectedStatus(ev.target.value);
                                onPushEvent('user_filter_status');
                            }}
                        >
                            {filterMenu.map((item) => {
                                return (
                                    <MenuItem value={item.value} id={item.id}>
                                        {t(item.label)}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Stack>
                <Drawer
                    PaperProps={{ sx: drawerStyles }}
                    open={timeRangeModalOpen}
                    anchor="bottom"
                    onClose={handleModalClose}
                >
                    <CommonModal
                        onClose={handleModalClose}
                        onConfirm={() => {
                            setTimeRange(tempTimeRange);
                            handleModalClose();
                        }}
                    >
                        <TimeRangeForm
                            value={tempTimeRange}
                            onChange={(val) => {
                                if (val.type === 'all') {
                                    setTempTimeRange({
                                        type: 'all',
                                        from: null,
                                        to: null,
                                    });
                                } else {
                                    setTempTimeRange(val);
                                }
                                onPushEvent(`user_use_filter_date_${val.type}`);
                            }}
                            timeSelectEnabled
                        />
                    </CommonModal>
                </Drawer>
            </>
        );
    };

    return {
        renderFilters,
        searchOrder,
        selectedStatus,
        setSearchOrder,
        timeRange,
    };
};
