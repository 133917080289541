import { Fragment } from 'react';
import { IOrderItem, IQsrOrderNotification } from '@/views/QsrOrders/types';
import { MenuCategoryResponse, MenuProductResponse } from '@/services/menuService/types';
import { getCurrencyWithAmount } from '@/common/utility';
import { QSRPrintLayoutEnum, QSRPrintSizeEnum, IRestaurant } from '@/views/OrdersTableView/types';
import { LayoutCashier } from '@/components/PosPrinteCenter/Layouts/Cashier';
import { LayoutKitchen } from '@/components/PosPrinteCenter/Layouts/Kitchen';
import { IPrintItem, ItemModeEnum } from '@/components/PosPrinteCenter';
import { LayoutQRInvoice } from '@/components/PosPrinteCenter/Layouts/QRInvoice';
import { IPaymentDetailsResponse } from '@/components/InvoiceTable/types';

interface IQsrRowsProps {
    items: IOrderItem[];
    productMap: { [key: string]: MenuProductResponse };
    categoryMap: { [key: string]: MenuCategoryResponse };
    currencyCode: string;
    currencySymbol: string;
    indent: number;
    hidePrice?: boolean;
    hideIndex?: boolean;
    xQty?: boolean;
    qty?: number;
    size?: QSRPrintSizeEnum;
}

export function OrderRows({
    items,
    productMap,
    categoryMap,
    currencyCode,
    currencySymbol,
    indent,
    hidePrice,
    hideIndex,
    xQty,
    qty,
    size,
}: IQsrRowsProps) {
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {items?.map((item, index) => {
                const finalQty = item.quantity * (qty || 1);
                return (
                    <Fragment key={`${item.id}_${index}`}>
                        {item.mgId && (
                            <tr
                                data-indent={indent}
                                data-mode="normal"
                                data-whitesapce="normal"
                                data-hideindex="true"
                                data-with-attr="true"
                                data-size={size}
                            >
                                <td data-type="name">
                                    {indent > 0 ? '  ' : ''}
                                    {categoryMap[item.mgId]?.name || item.mgId}
                                </td>
                            </tr>
                        )}
                        <tr
                            data-indent={indent}
                            data-mode={indent === 0 ? 'normal' : 'sub'}
                            data-whitesapce={currencySymbol.length > 1 ? 'expanded' : 'normal'}
                            data-hideindex={hideIndex ? 'true' : 'false'}
                            data-with-attr="true"
                            data-size={size}
                        >
                            <td data-type="name">
                                {indent > 0 ? '  ' : ''}
                                {hideIndex !== true ? `${index + 1} - ` : ''}
                                {hideIndex && xQty ? `${finalQty}x ` : ''}
                                {productMap[item.id]?.name || item.id}
                            </td>
                            {xQty !== true && <td data-type="qty">{finalQty}</td>}
                            {hidePrice !== true && (
                                <td align="right" data-type="price">
                                    {getCurrencyWithAmount(item.price, currencySymbol, currencyCode, true)}
                                </td>
                            )}
                        </tr>
                        {item.additives && (
                            <OrderRows
                                items={item.additives}
                                productMap={productMap}
                                categoryMap={categoryMap}
                                currencyCode={currencyCode}
                                currencySymbol={currencySymbol}
                                indent={indent + 1}
                                hidePrice={hidePrice}
                                hideIndex={hideIndex}
                                xQty={xQty}
                                qty={item.quantity}
                                size={size}
                            />
                        )}
                    </Fragment>
                );
            })}
        </>
    );
}

interface IProps {
    vendor: IRestaurant | null;
    order: IPrintItem;
    productMap: { [key: string]: MenuProductResponse };
    categoryMap: { [key: string]: MenuCategoryResponse };
    currencyCode: string;
    currencySymbol: string;
    size?: QSRPrintSizeEnum;
    itemSize?: QSRPrintSizeEnum;
    encoding?: string;
    onDone: (order: IPrintItem) => void;
}

export const PrintLayout = ({
    vendor,
    order,
    productMap,
    categoryMap,
    currencyCode,
    currencySymbol,
    size,
    itemSize,
    encoding,
    onDone,
}: IProps) => {
    if (order.type === ItemModeEnum.Order) {
        switch (order.layout) {
            default:
            case QSRPrintLayoutEnum.Cashier:
                return (
                    <LayoutCashier
                        vendor={vendor}
                        order={order.val as IQsrOrderNotification}
                        productMap={productMap}
                        categoryMap={categoryMap}
                        currencyCode={currencyCode}
                        currencySymbol={currencySymbol}
                        size={size}
                        itemSize={itemSize}
                        encoding={encoding}
                        onDone={(o) => {
                            onDone({
                                type: order.type,
                                layout: order.layout,
                                val: o,
                            });
                        }}
                    />
                );
            case QSRPrintLayoutEnum.Kitchen:
                return (
                    <LayoutKitchen
                        vendor={vendor}
                        order={order.val as IQsrOrderNotification}
                        productMap={productMap}
                        categoryMap={categoryMap}
                        currencyCode={currencyCode}
                        currencySymbol={currencySymbol}
                        size={size}
                        itemSize={itemSize}
                        encoding={encoding}
                        onDone={(o) => {
                            onDone({
                                type: order.type,
                                layout: order.layout,
                                val: o,
                            });
                        }}
                    />
                );
        }
    } else {
        return (
            <LayoutQRInvoice
                vendor={vendor}
                order={order.val as IPaymentDetailsResponse}
                currencyCode={currencyCode}
                currencySymbol={currencySymbol}
                onDone={(o) => {
                    onDone({
                        type: order.type,
                        layout: order.layout,
                        val: o,
                    });
                }}
            />
        );
    }
};
