import { CSSProperties, FC } from 'react';
import { Grid, IconButton, SxProps, DialogContent } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { useTranslation } from '@/hooks/translations';
import { PrimaryButton } from '@/components/common';

const outerContainer: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
};

const iconButtonContainer: SxProps = {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '.5rem',
};
const iconButton: SxProps = {
    margin: '0 .5rem',
};

const confirmButton: CSSProperties = {
    width: '15rem',
    borderRadius: '100px',
    margin: '1rem',
};

interface IProps {
    onClose: () => void;
    onConfirm: () => void;
    primaryButtonText?: string;
}

const CommonModal: FC<IProps> = ({ onConfirm, onClose, primaryButtonText, children }) => {
    const { t } = useTranslation('common');
    return (
        <Grid sx={outerContainer}>
            <Grid item sx={iconButtonContainer}>
                <IconButton sx={iconButton} onClick={onClose}>
                    <CloseRounded />
                </IconButton>
            </Grid>
            <DialogContent>{children}</DialogContent>
            <PrimaryButton text={primaryButtonText || t('Confirm')} styles={confirmButton} onClick={onConfirm} />
        </Grid>
    );
};

export default CommonModal;
